import React from 'react';
import { IconButton } from '@mui/material';

export const ResetIconButton = ({ className, onClick }) => {
  return (
    <IconButton className={className} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1006_2886)">
          <path
            d="M0.666016 2.66666V6.66666H4.66602"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.334 13.3333V9.33334H11.334"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.6593 6.00001C13.3212 5.04453 12.7466 4.19028 11.989 3.51696C11.2315 2.84363 10.3157 2.37319 9.32716 2.14952C8.33861 1.92584 7.30951 1.95624 6.33589 2.23786C5.36226 2.51948 4.47585 3.04315 3.75935 3.76001L0.666016 6.66668M15.3327 9.33334L12.2393 12.24C11.5229 12.9569 10.6364 13.4805 9.66281 13.7622C8.68918 14.0438 7.66009 14.0742 6.67154 13.8505C5.68299 13.6268 4.76722 13.1564 4.00966 12.4831C3.2521 11.8097 2.67746 10.9555 2.33935 10"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1006_2886">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconButton>
  );
};
