import { useState, useEffect } from 'react';
import vendorsService from '../axios/vendorsService';

export const useBrokersForManagePage = () => {
  const [brokersForManagePageInfo, setBrokersForManagePageInfo] =
    useState(null);
  const [brokersForManagePageInfoLoading, setBrokersForManagePageInfoLoading] =
    useState(null);

  useEffect(() => {
    const getData = async () => {
      setBrokersForManagePageInfoLoading(true);
      const response = await vendorsService.getBrokerForManagePage();
      setBrokersForManagePageInfo(response);
      setBrokersForManagePageInfoLoading(false);
    };

    getData();
  }, []);

  return { brokersForManagePageInfo, brokersForManagePageInfoLoading };
};
