import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import {
  ExpandLess,
  ExpandMore,
  DateRangeOutlined,
  PermIdentity,
  Engineering,
  PersonAdd,
  ManageAccounts,
  GroupAdd,
  Settings,
} from '@mui/icons-material';
import { List, Collapse } from '@mui/material';

export default function NavItem(props) {
  function getListItemIconClass(pathName) {
    const isSubSel =
      isDqSelected(pathName) ||
      isAdminSelected(pathName) ||
      isSISelected(pathName) ||
      isPSSelected(pathName);
    if (isSubSel) {
      return 'submenu-item';
    } else {
      return 'menu-item';
    }
  }

  function isDqSelected(pathName) {
    if (
      pathName?.includes('quick-facts') ||
      pathName?.includes('void-report') ||
      pathName?.includes('weekly-trend') ||
      pathName?.includes('customer-data')
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isSISelected(pathName) {
    if (
      pathName?.includes('service-and-inventory') ||
      pathName?.includes('produce') ||
      pathName?.includes('forecasts')
    ) {
      return true;
    } else {
      return false;
    }
  }
  function isPSSelected(pathName) {
    if (pathName?.includes('purchasing') || pathName?.includes('sales')) {
      return true;
    } else {
      return false;
    }
  }

  function isAdminSelected(pathName) {
    if (
      pathName?.includes('manage-vendor') ||
      pathName?.includes('create-vendor') ||
      pathName?.includes('manage-user') ||
      pathName?.includes('add-broker') ||
      pathName?.includes('manage-broker')
    ) {
      return true;
    } else {
      return false;
    }
  }

  function onListItemButtonClick() {
    if (props.name === 'dataQuery') {
      props.setAdminOpen(false);
      props.setSIOpen(false);
      props.setPSOpen(false);
      props.setOpen(!props.open);
    } else if (isDqSelected(props.path)) {
      props.setAdminOpen(false);
      props.setSIOpen(false);
      props.setPSOpen(false);
      return () => {};
    } else if (props.name === 'SIGroup') {
      props.setAdminOpen(false);
      props.setOpen(false);
      props.setPSOpen(false);
      props.setSIOpen(!props.SIOpen);
    } else if (isSISelected(props.path)) {
      props.setAdminOpen(false);
      props.setOpen(false);
      props.setPSOpen(false);
      return () => {};
    } else if (props.name === 'PSGroup') {
      props.setAdminOpen(false);
      props.setOpen(false);
      props.setSIOpen(false);
      props.setPSOpen(!props.PSOpen);
    } else if (isPSSelected(props.path)) {
      props.setAdminOpen(false);
      props.setOpen(false);
      props.setSIOpen(false);
      return () => {};
    } else if (props.name === 'admin') {
      props.setAdminOpen(!props.adminOpen);
      props.setOpen(false);
      props.setSIOpen(false);
      props.setPSOpen(false);
    } else if (isAdminSelected(props.path)) {
      props.setOpen(false);
      props.setSIOpen(false);
      props.setPSOpen(false);
      return () => {};
    } else {
      props.setOpen(false);
      props.setAdminOpen(false);
      props.setSIOpen(false);
      props.setPSOpen(false);
    }
  }

  function manageExpansionValue() {
    const isDq = props.name === 'dataQuery';
    const isAdmin = props.name === 'admin';
    const isSI = props.name === 'SIGroup';
    const isPS = props.name === 'PSGroup';

    if (isDq) {
      return getExpansionValue(isDq, props.open);
    } else if (isAdmin) {
      return getExpansionValue(isAdmin, props.adminOpen);
    } else if (isSI) {
      return getExpansionValue(isSI, props.SIOpen);
    } else if (isPS) {
      return getExpansionValue(isPS, props.PSOpen);
    } else {
      return <></>;
    }
  }

  function getExpansionValue(isParentLink, openState) {
    if (isParentLink && openState) {
      return <ExpandLess />;
    } else if (isParentLink) {
      return <ExpandMore />;
    }
  }

  function getParentElement() {
    if (props && props.path) {
      return (
        <NavLink
          to={props.path}
          className={({ isActive }) =>
            isActive ? 'active-link' : 'inactive-link'
          }
        >
          {getItemButton()}
        </NavLink>
      );
    } else if (props) {
      return getItemButton();
    } else {
      return <></>;
    }
  }

  function getItemButton() {
    return (
      <>
        <ListItemButton
          onClick={onListItemButtonClick}
          className={getListItemClass(
            props.location?.pathname,
            props.path,
            props.name,
          )}
        >
          <ListItemIcon className={getListItemIconClass(props.path)}>
            {getIconValue(props.name)}
          </ListItemIcon>
          {getListItemText()}
          {manageExpansionValue()}
        </ListItemButton>
        {getChildren()}
      </>
    );
  }

  function getListItemClass(currentLocaltionPath, componentlinkPath, name) {
    const isDqSel = isDqSelected(currentLocaltionPath);
    const isSISel = isSISelected(currentLocaltionPath);
    const isPSSel = isPSSelected(currentLocaltionPath);
    const isAdminSel = isAdminSelected(currentLocaltionPath);

    if (name === 'home' && currentLocaltionPath === componentlinkPath) {
      return 'current-page';
    } else if (name === 'home' && currentLocaltionPath !== componentlinkPath) {
      return 'inactive-page';
    } else if (name === 'dataQuery' && isDqSel) {
      return 'group-father-active';
    } else if (name === 'dataQuery' && !isDqSel) {
      return 'group-father-inactive';
    } else if (name === 'SIGroup' && isSISel) {
      return 'group-father-active';
    } else if (name === 'SIGroup' && !isSISel) {
      return 'group-father-inactive';
    } else if (name === 'PSGroup' && isPSSel) {
      return 'group-father-active';
    } else if (name === 'PSGroup' && !isPSSel) {
      return 'group-father-inactive';
    } else if (name === 'admin' && isAdminSel) {
      return 'group-father-active';
    } else if (name === 'admin' && !isAdminSel) {
      return 'group-father-inactive';
    } else if (currentLocaltionPath?.includes(componentlinkPath)) {
      return 'current-page';
    } else {
      return 'inactive-page';
    }
  }

  function getListItemText() {
    const isSubSel = isDqSelected(props.path) || isAdminSelected(props.path);
    if (isSubSel) {
      return (
        <ListItemText className="secondary-text" secondary={props.title} />
      );
    } else if (
      props.name === 'dataQuery' ||
      props.name === 'admin' ||
      props.name === 'SIGroup' ||
      props.name === 'PSGroup'
    ) {
      return (
        <ListItemText className="primary-text dq-text" primary={props.title} />
      );
    } else {
      return <ListItemText className="primary-text" primary={props.title} />;
    }
  }

  function getChildren() {
    if (props.children) {
      return (
        <Collapse
          in={
            props.name === 'dataQuery'
              ? props.open
              : props.name === 'admin'
                ? props.adminOpen
                : props.name === 'SIGroup'
                  ? props.SIOpen
                  : props.PSOpen
          }
          timeout="auto"
          unmountOnExit
        >
          <List className="list">
            {props.children.map((navItem) => (
              <NavItem
                key={navItem.name}
                name={navItem.name}
                title={navItem.title}
                path={navItem.path}
                open={props.open}
                setOpen={props.setOpen}
                SIOpen={props.SIOpen}
                setSIOpen={props.setSIOpen}
                PSOpen={props.PSOpen}
                setPSOpen={props.setPSOpen}
                adminOpen={props.adminOpen}
                setAdminOpen={props.setAdminOpen}
                location={props.location}
              >
                {navItem.children}
              </NavItem>
            ))}
          </List>
        </Collapse>
      );
    } else {
      return <></>;
    }
  }

  return <>{getParentElement(props)}</>;
}

function getIconValue(name) {
  switch (name) {
    case 'home':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 22V12H15V22"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'dataQuery':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8C16.9706 8 21 6.65685 21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5C3 6.65685 7.02944 8 12 8Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 5V19C3 20.66 7 22 12 22C17 22 21 20.66 21 19V5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'qf':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 9H21"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 21V9"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'vr':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2V6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 18V22"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.92999 4.92993L7.75999 7.75993"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.24 16.24L19.07 19.07"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 12H6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 12H22"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.92999 19.07L7.75999 16.24"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.24 7.75993L19.07 4.92993"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'wt':
      return <DateRangeOutlined />;
    case 'sp':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_397_2533)">
            <path
              d="M21 8C19.55 8 18.74 9.44 19.07 10.51L15.52 14.07C15.22 13.98 14.78 13.98 14.48 14.07L11.93 11.52C12.27 10.45 11.46 9 10 9C8.55 9 7.73 10.44 8.07 11.52L3.51 16.07C2.44 15.74 1 16.55 1 18C1 19.1 1.9 20 3 20C4.45 20 5.26 18.56 4.93 17.49L9.48 12.93C9.78 13.02 10.22 13.02 10.52 12.93L13.07 15.48C12.73 16.55 13.54 18 15 18C16.45 18 17.27 16.56 16.93 15.48L20.49 11.93C21.56 12.26 23 11.45 23 10C23 8.9 22.1 8 21 8Z"
              fill="black"
            />
            <path
              d="M15 9L15.94 6.93L18 6L15.94 5.07L15 3L14.08 5.07L12 6L14.08 6.93L15 9Z"
              fill="black"
            />
            <path
              d="M3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_397_2533">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'fyr':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 20V10"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 20V4"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 20V16"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'SIGroup':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.1821 12.2223C2.1821 6.69949 6.57778 2.22237 12.0002 2.22237C17.4225 2.22237 21.8182 6.69949 21.8182 12.2223C21.8182 12.8328 21.6769 14.0886 21.3914 15.9064C21.2223 16.9846 20.309 17.7779 19.2371 17.7779H4.78496C3.71059 17.7779 2.79596 16.9811 2.62957 15.8999C2.33189 13.9665 2.1821 12.6947 2.1821 12.2223ZM0.474176 16.2441C0.806924 18.4064 2.63611 20 4.78491 20H19.2371C21.3809 20 23.2074 18.4137 23.5458 16.2574C23.8486 14.3291 24 12.984 24 12.2223C24 5.47235 18.6277 0 12 0C5.37235 0 8.93556e-07 5.47188 8.93556e-07 12.2223C8.93556e-07 12.8518 0.157974 14.1924 0.473933 16.2441H0.474176Z"
            fill="black"
          />
          <path
            d="M9.81818 13.3332C9.81818 14.5604 10.7951 15.5554 12 15.5554C13.205 15.5554 14.1819 14.5604 14.1819 13.3332C14.1819 12.1059 13.205 11.1111 12 11.1111C10.7951 11.1111 9.81818 12.1059 9.81818 13.3332Z"
            fill="black"
          />
          <path
            d="M11.5929 14.6636L5.76289 8.55223L7.32784 7.00366L13.1579 13.1151L11.5929 14.6636Z"
            fill="black"
          />
        </svg>
      );
    case 'serviceInventory':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.1821 12.2223C2.1821 6.69949 6.57778 2.22237 12.0002 2.22237C17.4225 2.22237 21.8182 6.69949 21.8182 12.2223C21.8182 12.8328 21.6769 14.0886 21.3914 15.9064C21.2223 16.9846 20.309 17.7779 19.2371 17.7779H4.78496C3.71059 17.7779 2.79596 16.9811 2.62957 15.8999C2.33189 13.9665 2.1821 12.6947 2.1821 12.2223ZM0.474176 16.2441C0.806924 18.4064 2.63611 20 4.78491 20H19.2371C21.3809 20 23.2074 18.4137 23.5458 16.2574C23.8486 14.3291 24 12.984 24 12.2223C24 5.47235 18.6277 0 12 0C5.37235 0 8.93556e-07 5.47188 8.93556e-07 12.2223C8.93556e-07 12.8518 0.157974 14.1924 0.473933 16.2441H0.474176Z"
            fill="black"
          />
          <path
            d="M9.81818 13.3332C9.81818 14.5604 10.7951 15.5554 12 15.5554C13.205 15.5554 14.1819 14.5604 14.1819 13.3332C14.1819 12.1059 13.205 11.1111 12 11.1111C10.7951 11.1111 9.81818 12.1059 9.81818 13.3332Z"
            fill="black"
          />
          <path
            d="M11.5929 14.6636L5.76289 8.55223L7.32784 7.00366L13.1579 13.1151L11.5929 14.6636Z"
            fill="black"
          />
        </svg>
      );
    case 'produce':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#000000"
        >
          <path d="M204-144q-45 0-76.5-31.5T96-252q0-45 31.5-76.5T204-360h552q45 0 76.5 31.5T864-252q0 45-31.5 76.5T756-144H204Zm0-72h552q15.3 0 25.65-10.29Q792-236.58 792-251.79t-10.35-25.71Q771.3-288 756-288H204q-15.3 0-25.65 10.29Q168-267.42 168-252.21t10.35 25.71Q188.7-216 204-216Zm216-216q-15.3 0-25.65-10.35Q384-452.7 384-468v-312q0-15.3 10.35-25.65Q404.7-816 420-816h312q15.3 0 25.65 10.35Q768-795.3 768-780v312q0 15.3-10.35 25.65Q747.3-432 732-432H420Zm36-72h240v-240H456v240ZM96-528v-72h192v72H96Zm408-108h144v-72H504v72Zm-336-12h120v-72H168v72Zm288 144v-240 240Z" />
        </svg>
      );
    case 'forecasts':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#000000"
        >
          <path d="m107-246-59-42 192-312 120 144 168-264 120 168q-21 0-40.5 3T569-539l-38-52-163 257-119-143-142 231ZM861-48 738-171q-20 13-42.5 20t-47.5 7q-70 0-119-49t-49-119q0-70 49-119t119-49q70 0 119 49t49 119q0 25-7 47.5T789-222L912-99l-51 51ZM648-216q40 0 68-28t28-68q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28Zm78-323q-18-7-38-10t-40-3l205-312 59 42-186 283Z" />
        </svg>
      );
    case 'PSGroup':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 1V23"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'purchase':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 1V23"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'sales':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#000000"
        >
          <path d="M856-390 570-104q-12 12-27 18t-30 6q-15 0-30-6t-27-18L103-457q-11-11-17-25.5T80-513v-287q0-33 23.5-56.5T160-880h287q16 0 31 6.5t26 17.5l352 353q12 12 17.5 27t5.5 30q0 15-5.5 29.5T856-390ZM513-160l286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640Zm220 160Z" />
        </svg>
      );
    case 'customer-data':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 14.9L14.0769 17L21 10"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 18.1875V19C17 19.5304 16.8595 20.0391 16.6095 20.4142C16.3594 20.7893 16.0203 21 15.6667 21H6.33333C5.97971 21 5.64057 20.7893 5.39052 20.4142C5.14048 20.0391 5 19.5304 5 19V5C5 4.46957 5.14048 3.96086 5.39052 3.58579C5.64057 3.21071 5.97971 3 6.33333 3H13.6667"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 3H15.6154C15.9826 3 16.3348 3.21406 16.5945 3.59508C16.8541 3.97611 17 4.49289 17 5.03175V7"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 3L14 3"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M8 8H14"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 11H14"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'admin':
      return <Settings />;
    case 'mv':
      return <ManageAccounts />;
    case 'av':
      return <PersonAdd />;
    case 'mu':
      return <PermIdentity />;
    case 'ab':
      return <GroupAdd />;
    case 'mb':
      return <Engineering />;
    case 'faq':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.6564 11.9584H11.2075C11.2075 11.7644 11.2075 11.6351 11.2075 11.5982C11.2075 11.1547 11.2853 10.7852 11.4408 10.4896C11.5964 10.194 11.9076 9.87991 12.3744 9.51963C12.8412 9.15935 13.1232 8.91917 13.2107 8.80831C13.3468 8.63279 13.4246 8.4388 13.4246 8.22633C13.4246 7.93072 13.2982 7.68129 13.0551 7.46882C12.812 7.25635 12.4716 7.15473 12.0535 7.15473C11.6353 7.15473 11.3047 7.26559 11.0324 7.4873C10.7601 7.70901 10.5754 8.04157 10.4684 8.49423L9 8.31871C9.0389 7.67206 9.33063 7.12702 9.86548 6.67436C10.4003 6.22171 11.1102 6 11.9854 6C12.9092 6 13.6386 6.23095 14.1831 6.6836C14.7277 7.13626 15 7.67206 15 8.28176C15 8.61432 14.9028 8.93764 14.6985 9.23326C14.4943 9.52887 14.0762 9.94457 13.4149 10.4527C13.0746 10.7206 12.8703 10.933 12.7828 11.0993C12.6953 11.2656 12.6661 11.552 12.6759 11.9677L12.6564 11.9584ZM11.2075 14V12.4758H12.812V14H11.2075Z"
            fill="black"
          />
        </svg>
      );
    case 'training':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10L12 5L3 10L6 11.6667M21 10L18 11.6667M21 10V10C21.6129 10.3064 22 10.9328 22 11.618V16.9998M6 11.6667L12 15L18 11.6667M6 11.6667V17.6667L12 21L18 17.6667L18 11.6667"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      break;
  }
}
