import { useState, useEffect } from 'react';
import { formatVendorData } from '../utils/helpers';
import vendorsService from '../axios/vendorsService';

export const useSubscribedVendorAttributes = (userGroup, refetch) => {
  const [subscribedVendorAttributes, setSubscribedVendorAttributes] =
    useState(null);
  const [
    subscribedVendorAttributesLoading,
    setSubscribedVendorAttributesLoading,
  ] = useState(true);

  useEffect(() => {
    const fetchSubscribedVendors = async () => {
      setSubscribedVendorAttributesLoading(true);
      setSubscribedVendorAttributes(null);
      const selectedVendorsResponse =
        await vendorsService.getVendorsAttributes();
      const vendorData = formatVendorData(selectedVendorsResponse);
      setSubscribedVendorAttributes(vendorData);
      setSubscribedVendorAttributesLoading(false);
    };

    fetchSubscribedVendors();
  }, [userGroup, refetch]);

  return { subscribedVendorAttributes, subscribedVendorAttributesLoading };
};
