/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Card, Flex } from '@aws-amplify/ui-react';
import {
  getToggledFilters,
  formatFilters,
  removeTags,
  getFirstAndLastFromArray,
} from '../../utils/helpers';
import { ReactComponent as FilterSvg } from './assets/filter.svg';
import { ReactComponent as ArrowCloseSvg } from './assets/arrowClose.svg';
import { encodeDates } from '../../utils/dateUtils';
import { FiltersContext } from '../../components/Navigation/Routes';
import MuiDrawer from '@mui/material/Drawer';
import './FilterSummary.css';

export function FilterSummary(page) {
  const context = useContext(FiltersContext);
  const { viewType, filters, currentTimeframe, previousTimeframe } = context;

  // STATE VARS
  const [open, setOpen] = useState(false);
  const [displayedFilters, setDisplayedFilters] = useState([]);

  // CONSTANTS
  const drawerWidth = 180;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: '#E5E5E6',
    position: 'relative',
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `49px`,
    [theme.breakpoints.up('sm')]: {
      width: `49px`,
    },
    border: 'none',
    position: 'relative',
    backgroundColor: '#E5E5E6',
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  useEffect(() => {
    const currFilters = {
      ...filters,
      'previous-timeframe': encodeDates(previousTimeframe),
      'current-timeframe': encodeDates(currentTimeframe),
    };

    const toggledFilters = getToggledFilters(viewType, currFilters);
    const detaggedFilters = removeTags(viewType, toggledFilters, page?.page);
    const displayedFilters = formatFilters(detaggedFilters);
    const transformedFiltersFirstAndLastPrevious = getFirstAndLastFromArray(
      displayedFilters[displayedFilters.length - 2][1],
    );
    const transformedFiltersFirstAndLastCurrent = getFirstAndLastFromArray(
      displayedFilters[displayedFilters.length - 1][1],
    );
    displayedFilters[displayedFilters.length - 2][1] =
      transformedFiltersFirstAndLastPrevious;
    displayedFilters[displayedFilters.length - 1][1] =
      transformedFiltersFirstAndLastCurrent;
    setDisplayedFilters(displayedFilters);
  }, [JSON.stringify(filters), viewType, previousTimeframe, currentTimeframe]);

  return (
    <Drawer className="filter-summary-drawer" variant="permanent" open={open}>
      <List className="main-list">
        <ListItem key={'Filters'} className="list-item-label">
          <ListItemButton
            className="main-itemButton-list"
            onClick={() => {
              open ? setOpen(false) : setOpen(true);
            }}
            sx={{ justifyContent: open ? 'initial' : 'center', padding: 0 }}
          >
            <ListItemIcon
              className="filter-summary-filter-icon"
              sx={{ mr: open ? 0 : 'auto', width: open ? '0px' : 'auto' }}
            >
              <FilterSvg />
            </ListItemIcon>

            <ArrowCloseSvg />
          </ListItemButton>
        </ListItem>

        {open ? <Divider className="filter-separator" /> : null}

        {displayedFilters.map(([dispKey, dispValArr]) => {
          return (
            <ListItem
              className="filter-items"
              key={`${dispKey}_key`}
              style={{ opacity: open ? 1 : 0 }}
            >
              <Card key={`${dispKey}_key`} padding={'0'} borderRadius={'10px'}>
                <Flex direction={'row'}>
                  <div className="filter-summary-left-div"></div>
                  <div className="filter-summary-cards">
                    <p className="filter-summary-card-headers">
                      <strong>{dispKey}</strong>
                    </p>

                    {dispKey === 'Current Timeframe' ||
                    dispKey === 'Previous Timeframe' ? (
                      <p className="main-list-date">
                        {dispValArr[0]} - {dispValArr[1]}
                      </p>
                    ) : (
                      <ul className="filter-summary-card-content">
                        {dispValArr.slice(0, 5).map((dispVal) => {
                          return <li key={`${dispVal}_key`}>{dispVal}</li>;
                        })}
                      </ul>
                    )}

                    <p className="filter-summary-card-content">
                      {dispValArr.length > 5
                        ? `... ${dispValArr.length - 5} more values selected`
                        : ''}
                    </p>
                  </div>
                </Flex>
              </Card>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
