import React, { useContext } from 'react';
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import { Flex } from '@aws-amplify/ui-react';
import { useIdleTimer } from '../../../utils/IdleTimer';
import { FiltersContext } from '../../../components/Navigation/Routes';
import featureToggle from '../../../featureToggle.json';
import Dev from '../../dev/Dev';

export const FourYearTrend = ({ page }) => {
  const context = useContext(FiltersContext);
  const { channel } = context;

  const environment = process.env.REACT_APP_ENVIRONMENT;

  useIdleTimer(channel);

  if (featureToggle[environment][page]) {
    return (
      <Flex direction={'row'} height={'100%'}>
        <Flex
          direction={'column'}
          width={'100%'}
          height={'100%'}
          overflow={'hidden'}
          paddingTop={'12px'}
        >
          <SigmaEmbed page={page} />
        </Flex>
      </Flex>
    );
  } else {
    return <Dev />;
  }
};
