import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';

export default function RoleError() {
  useEffect(() => {
    async function handleLogOut() {
      await sleep(5000);
      Auth.signOut();
    }
    handleLogOut();
  }, []);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return (
    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'column',
        }}
      >
        <h2>UNABLE TO RETRIEVE ROLE, PLEASE CONTACT SUPPORT</h2>

        <h2>Signing Out...</h2>
      </div>
    </div>
  );
}
