import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CMS from '../cms/cms.json';
import '../App.css';
import { Hub } from 'aws-amplify';
import usersService from '../axios/usersService';
import NavItem from '../components/Navigation/NavItem';
import { FiltersContext } from '../components/Navigation/Routes';
import featureToggle from '../featureToggle.json';

export function MainListItems() {
  const [open, setOpen] = useState(false);
  const [SIOpen, setSIOpen] = useState(false);
  const [PSOpen, setPSOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const location = useLocation();
  const [navItems, setNavItems] = useState([]);
  const context = useContext(FiltersContext);
  const navigate = useNavigate();
  const { setSubId, impersonatorInfo } = context;

  useEffect(() => {
    Hub.listen('refresh', async () => {
      const subIdRes = await usersService.getSubId();
      setSubId(subIdRes);
      let navRes = await usersService.getNav();
      if (!featureToggle[process.env.REACT_APP_ENVIRONMENT]['customer-data']) {
        let objectToChange = navRes.find((obj) => obj.name === 'dataQuery');
        objectToChange.children = objectToChange.children.slice(0, 3);
      }
      setNavItems(navRes);
    });
  }, []);

  useEffect(() => {
    const fetchNavRoutes = async () => {
      let navRes = await usersService.getNav(impersonatorInfo);
      if (!featureToggle[process.env.REACT_APP_ENVIRONMENT]['customer-data']) {
        let objectToChange = navRes.find((obj) => obj.name === 'dataQuery');
        objectToChange.children = objectToChange.children.slice(0, 3);
      }
      setNavItems(navRes);
    };
    fetchNavRoutes();
    navigate('/');
  }, [impersonatorInfo.name]);

  return (
    <div className="menu-list">
      {navItems?.map((navItem) => (
        <NavItem
          key={navItem.name}
          name={navItem.name}
          title={navItem.title}
          path={navItem.path}
          open={open}
          setOpen={setOpen}
          SIOpen={SIOpen}
          setSIOpen={setSIOpen}
          PSOpen={PSOpen}
          setPSOpen={setPSOpen}
          adminOpen={adminOpen}
          setAdminOpen={setAdminOpen}
          location={location}
        >
          {navItem.children}
        </NavItem>
      ))}
    </div>
  );
}

export function SecondaryListItems() {
  const location = useLocation();
  return (
    <div className="menu-list">
      <NavItem
        key="training"
        name="training"
        title="TRAINING"
        path="/training"
        open={false}
        setOpen={() => {}}
        SIOpen={false}
        setSIOpen={() => {}}
        PSOpen={false}
        setPSOpen={() => {}}
        adminOpen={false}
        setAdminOpen={() => {}}
        location={location}
      />
      <NavItem
        key="faq"
        name="faq"
        title="FAQs"
        path="/faqs"
        open={false}
        setOpen={() => {}}
        SIOpen={false}
        setSIOpen={() => {}}
        PSOpen={false}
        setPSOpen={() => {}}
        adminOpen={false}
        setAdminOpen={() => {}}
        location={location}
      />
      <div className="copy-right">
        {CMS.pages.find((o) => o.name === 'copyRight')?.title}
      </div>
    </div>
  );
}
