import { sortObject } from '../utils/helpers';
import { useState, useEffect } from 'react';
import vendorsService from '../axios/vendorsService';
import featureToggle from '../featureToggle.json';

export const useBrokerAreas = (isChecked, selectedVendors) => {
  const [brokerAreas, setBrokerAreas] = useState(null);
  const [brokerAreasLoaded, setBrokerAreasLoaded] = useState(false);
  const [childVendors, setChildVendors] = useState(null);

  useEffect(() => {
    let childVendors = '';
    for (let index = 0; index < selectedVendors.length; index++) {
      childVendors += `'${selectedVendors[index]}'`;
      if (index !== selectedVendors.length - 1) {
        childVendors += ',';
      }
    }

    setChildVendors(childVendors);
  }, [JSON.stringify(selectedVendors)]);

  useEffect(() => {
    setBrokerAreasLoaded(true);
    setBrokerAreas(null);

    if (isChecked) {
      const fetchBrokerAreas = async (child_vendors) => {
        if (!child_vendors) {
          return;
        }
        setBrokerAreasLoaded(false);
        const data = await vendorsService.getBrokerAreas(child_vendors);
        let brokerData = [];
        if (data) {
          brokerData = data.map((broker) => {
            return {
              label: broker.area_nm,
              id: broker.area_id,
            };
          });
          brokerData = brokerData.sort(sortObject);
        }
        setBrokerAreas(brokerData);
        setBrokerAreasLoaded(true);
      };

      if (!featureToggle[process.env.REACT_APP_ENVIRONMENT]['broker']) return;
      childVendors !== ''
        ? fetchBrokerAreas(childVendors)
        : fetchBrokerAreas('null');
    }
  }, [isChecked, JSON.stringify(childVendors)]);

  return { brokerAreas, brokerAreasLoaded };
};
