import { notification } from 'antd';
import axios from 'axios';

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'The new or changed data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The information was successfully deleted',
  400: 'The request was made with an error and the server did not perform any new or changed data operations.',
  401: 'The unauthenticated user (wrong token, username, or password).',
  403: 'The user does not have permission.',
  404: 'The request is made for a record that does not exist and the server is down.',
  406: 'The application format is not available.',
  410: 'The requested resource is permanently deleted and will not be recovered.',
  422: 'A validation error occurred while creating an object.',
  500: 'A server error occurred. Check the server.',
  502: 'Gateway error',
  503: 'The service is unavailable and the server is temporarily overloaded or undergoing maintenance.',
  504: 'The gateway timed out.',
};
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  notification.error({
    message: `Error making SNOWFLAKE Request ${response.status}: ${response.url}`,
    description: errortext,
  });
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
}

export function axiosRequest(option, hideNotification = true) {
  let url = process.env.REACT_APP_URL;
  return axios({
    url: url,
    method: option.method,
    headers: {
      ...option.headers,
    },
    data: option.body,
  })
    .then(checkStatus)
    .then((response) => {
      return response; //.json();
    })
    .catch(function (e) {
      const status = e.response?.data ?? null;
      if (status === 401 && !hideNotification) {
        console.log('401');

        return;
      }
      if (status === 403 && !hideNotification) {
        notification.error({
          message: 'Error making SNOWFLAKE Request',
          description: 'You do not have permissions for that action',
        });

        return;
      }

      let message = 'Error making Request.';

      if (e.response && e.response.data) {
        if (e.response.data.message) {
          message = e.response.data.message;
        }
      } else if (e.message) {
        message = e.message;
      }
      //let message = e.response.data ? e.response.data : e.message;

      if (!hideNotification) {
        notification.error({
          message: `Request failed`,
          description: message,
        });
      }
      return e.response ? e.response.data : null;
    });
}
