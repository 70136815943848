import React, { useState, useRef, useContext } from 'react';
import { Input } from 'antd';
import { Select } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';
import { SaveIconButton } from '../SaveIconButton';
import { DeleteIconButton } from '../DeleteIconButton';
import { ResetIconButton } from '../ResetIconButton';
import { AmplifyAlertBanner } from '../AmplifyAlertBanner';
import { mapSavedFiltersToMenuItems } from './helpers';
import { FiltersContext } from '../Navigation/Routes';
import SharedProfiles from '../SharedProfiles';
import {
  handleFilterSelectChange,
  handleSaveFiltersInTable,
  handleGetFiltersFromTable,
  handleDeleteSavedFilter,
} from './handlers';
import FilterConfirm from './FilterConfirm';
import { validateInputs } from './helpers';

export default function FilterConfigMenu(props) {
  const context = useContext(FiltersContext);
  const {
    viewType,
    savedFiltersName,
    filters,
    setFilters,
    filtersNameInput,
    setFiltersNameInput,
    handleResetFilters,
    quickFactsWorkbookControlData,
    setQuickFactsWorkbookControlData,
    voidReportWorkbookControlData,
    setVoidReportWorkbookControlData,
    weeklyWorkbookControlData,
    setWeeklyWorkbookControlData,
  } = context;
  const controlData = {
    'Quick Facts': {
      data: quickFactsWorkbookControlData,
      setData: setQuickFactsWorkbookControlData,
    },
    'Void Report': {
      data: voidReportWorkbookControlData,
      setData: setVoidReportWorkbookControlData,
    },
    'Weekly Trend': {
      data: weeklyWorkbookControlData,
      setData: setWeeklyWorkbookControlData,
    },
  };
  const [config, setConfig] = useState({ variation: '', msg: '' });
  const [opacityClass, setOpacityClass] = useState('enabled');
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [componentDisabled, setComponentDisabled] = useState(false);

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleSaveClick = () => {
    const filtertype =
      props?.page === 'customer-data' || props?.page === 'purchase'
        ? props?.page
        : viewType;

    if (props?.page === 'customer-data' || props?.page === 'purchase') {
      setComponentDisabled(true);
      setOpacityClass('enabled');

      handleSaveFiltersInTable(
        filtertype,
        filters,
        filtersNameInput,
        setFiltersNameInput,
        savedFiltersName,
        setConfig,
        props.page,
      );

      setComponentDisabled(false);
    } else {
      const validate = validateInputs(
        filtersNameInput,
        filters,
        setConfig,
        'insert',
      );
      if (!validate) return;

      setPopupVisible(true);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const savedFilters = useRef(null);

  const updateSavedFiltersOptions = (options) => {
    setOpacityClass('disabled');
    savedFilters.current = options;
  };

  let title;

  if (props.page === 'customer-data') {
    title = 'Customer';
  } else if (props.page === 'purchase') {
    title = 'Purchase';
  } else if (viewType === 'basic') {
    title = 'Portfolio';
  } else {
    title = 'Category';
  }

  return (
    <>
      <AmplifyAlertBanner isDismissible={true} config={config} />

      <div className="filter-load-save-div">
        <h2>{title}</h2>
        <Input
          className="save-filters-input"
          placeholder="NEW FILTER NAME"
          onChange={(event) => setFiltersNameInput(event.target.value)}
          value={filtersNameInput}
        />
        <SaveIconButton
          className="save-filters-icon-button"
          disabled={componentDisabled}
          onClick={handleSaveClick}
        />

        {isPopupVisible && (
          <FilterConfirm
            onClose={handleClosePopup}
            setConfig={setConfig}
            controlData={controlData}
          />
        )}

        <Select
          className={`load-filters-select opacity-${opacityClass}`}
          defaultValue=""
          placeholder={
            opacityClass === 'disabled' ? '' : savedFiltersName.current
          }
          value={opacityClass === 'enabled' ? '' : savedFiltersName.current}
          onOpen={() => {
            setOpacityClass('enabled');
            const filtertype =
              props?.page === 'customer-data' || props?.page === 'purchase'
                ? props?.page
                : viewType;
            handleGetFiltersFromTable(
              filtertype,
              setIsDropdownLoading,
              updateSavedFiltersOptions,
              setFiltersNameInput,
            );
          }}
          onChange={(event) => {
            const selected = event.target.value;
            setOpacityClass('disabled');
            const filtertype =
              props?.page === 'customer-data' || props?.page === 'purchase'
                ? props?.page
                : viewType;
            handleFilterSelectChange(
              selected,
              savedFilters.current,
              savedFiltersName,
              setFilters,
              setFiltersNameInput,
              filtertype,
              controlData,
            );
          }}
        >
          {isDropdownLoading ? (
            <Loader />
          ) : (
            mapSavedFiltersToMenuItems(savedFilters.current)
          )}
        </Select>
        <DeleteIconButton
          disabled={componentDisabled}
          className="delete-icon-button"
          onClick={async () => {
            setComponentDisabled(true);

            if (savedFiltersName.current) {
              const filtertype =
                props?.page === 'customer-data' || props?.page === 'purchase'
                  ? props?.page
                  : viewType;
              await handleDeleteSavedFilter(
                filtertype,
                savedFiltersName,
                setConfig,
                setFiltersNameInput,
              );
            } else {
              setConfig({
                variation: 'error',
                msg: 'No filter preset selected to delete',
              });
            }

            setComponentDisabled(false);
          }}
        />
        <ResetIconButton
          className="reset-icon-button"
          onClick={handleResetFilters}
        />
      </div>

      <div className="filter-import-export">
        <h2>Share Filters</h2>
        <SharedProfiles page={props?.page} />
      </div>
    </>
  );
}
