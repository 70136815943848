import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { SignInHeader } from '../components/login/SignInHeader';
import { Loader } from '@aws-amplify/ui-react';

export const Login = () => {
  const WAIT_TIME = 1000;
  const notInitialRender = useRef(false);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const email = useRef();
  const otpCode = useRef();

  const navigate = useNavigate();

  //Handles the enter key press for the login
  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (email.current && !cognitoUser) {
      onEmailSubmit(event);
    } else if (otpCode.current && cognitoUser) {
      onOTPSubmit(event);
    }
  };

  //Handles the submit of the form
  const onEmailSubmit = (event) => {
    event.preventDefault();
    if (!email.current) {
      setError(true);
      setErrorMsg('No email provided');
      return;
    }

    if (email.current && email.current.includes('@usfoods.com')) {
      setError(true);
      setErrorMsg('Please Use the Employee Sign In');
      return;
    }

    setLoading(true);
    signIn(email.current)
      .then((data) => {
        setCognitoUser(data);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setErrorMsg('Email not recognized, please contact an administrator');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOTPSubmit = (event) => {
    event.preventDefault();
    answerCustomChallenge(
      cognitoUser,
      otpCode.current,
      setCognitoUser,
      setLoading,
    );
  };

  useEffect(() => {
    if (notInitialRender.current && shouldRedirect) {
      const interval = setInterval(() => {
        setShouldRedirect(false);
      }, WAIT_TIME);
      return () => clearInterval(interval);
    } else {
      notInitialRender.current = true;
    }
  }, [shouldRedirect, navigate]);

  return (
    <div className="login-div">
      <div className="login">
        <img alt="usf-logo" className="login-logo" src="/USF-ST-logo.png" />
        <>
          {cognitoUser ? null : (
            <>
              <SignInHeader />
              <br />
              <div className="inputs">
                <h3 className="email-header">Collaborative Partner</h3>
                <TextField
                  id="standard-basic"
                  className="login-email-field"
                  error={error}
                  required
                  helperText={error ? errorMsg : null}
                  placeholder="Enter an email"
                  onKeyDown={handleKeyPress}
                  onChange={(e) => (email.current = e.target.value)}
                />
                {loading ? (
                  <Loader size="large" className="login-loader" />
                ) : (
                  <Button
                    className="login-button"
                    onKeyDown={handleKeyPress}
                    variant="contained"
                    size="large"
                    onClick={onEmailSubmit}
                  >
                    Send OTP Code
                  </Button>
                )}
              </div>
            </>
          )}
          {cognitoUser ? (
            <>
              <div>
                <h3 className="otp-header">Verify OTP Code sent to Email</h3>
                <TextField
                  id="standard-basic"
                  className="login-email-field"
                  inputProps={{
                    style: {
                      border: 'none',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    },
                  }}
                  error={error}
                  helperText={error ? 'Invalid OTP Code' : null}
                  placeholder="OTP Code"
                  onKeyDown={handleKeyPress}
                  onChange={(e) => (otpCode.current = e.target.value)}
                />
              </div>
              {loading ? (
                <Loader size="large" className="login-loader" />
              ) : (
                <Button
                  className="verify-otp-button"
                  disabled={email.current ? false : true}
                  onKeyDown={handleKeyPress}
                  variant="contained"
                  size="large"
                  onClick={onOTPSubmit}
                >
                  Verify
                </Button>
              )}
            </>
          ) : null}
        </>
      </div>
    </div>
  );
};

async function signIn(email) {
  return await Auth.signIn(email);
}

async function answerCustomChallenge(
  cognitoUser,
  secretCode,
  setCognitoUser,
  setLoading,
) {
  // This will throw an error if it’s the 3rd wrong answer
  try {
    setLoading(true);
    const challengeResult = await Auth.sendCustomChallengeAnswer(
      cognitoUser,
      secretCode,
    );
    if (challengeResult.challengeName) {
      const attemptsLeft = parseInt(
        challengeResult.challengeParam.attemptsLeft,
      );

      alert(
        `The code you entered is incorrect. ${attemptsLeft} attempts left.`,
      );
    } else {
      setCognitoUser(challengeResult);
    }
  } catch (error) {
    setCognitoUser(null);
    alert('Too many failed attempts. Please try again.');
  } finally {
    setLoading(false);
  }
}
