import React from 'react';
import featureToggle from '../../../featureToggle.json';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import CustomAccordion from '../../../layouts/CustomAccordion';
import DateOptions from '../../../layouts/DateOptions';
import Dev from '../../dev/Dev';
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import { FilterSummary } from '../../../layouts/FilterSummary/FilterSummary';
import { Flex } from '@aws-amplify/ui-react';
import { useState, useContext } from 'react';
import { useIdleTimer } from '../../../utils/IdleTimer';
import { FiltersContext } from '../../../components/Navigation/Routes';
import { Button } from '@mui/material';

export const Purchasing = ({ page }) => {
  const context = useContext(FiltersContext);
  const { channel, handleResetFilters, switchOn, setSwitchOn } = context;

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const [switchDisabled, setSwitchDisabled] = useState(true);
  const [checked, setChecked] = useState(true);

  useIdleTimer(channel);

  if (featureToggle[environment][page])
    return (
      <Flex direction={'row'} height={'100%'}>
        <Flex
          direction={'column'}
          width={'100%'}
          height={'100%'}
          overflow={'hidden'}
          paddingTop={'12px'}
        >
          <>
            <Flex direction={'column'}>
              <Flex
                direction={'row'}
                alignItems={'end'}
                justifyContent={'end'}
                margin={'0px 12px 12px 12px'}
              >
                <CustomAccordion page={page} />
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleResetFilters(page)}
                    style={{
                      width: '100%',
                      height: '36.5px',
                      backgroundColor: '#E46F4E',
                      textTransform: 'none',
                      borderRadius: '2px',
                    }}
                    disableElevation
                  >
                    <div style={{ display: 'contents', color: 'white' }}>
                      <CachedOutlinedIcon
                        style={{
                          fill: 'white',
                          width: '20px',
                          backgroundColor: '#E46F4E',
                        }}
                      />
                    </div>
                  </Button>
                </div>
              </Flex>
            </Flex>
          </>
          <DateOptions
            page={page}
            checked={checked}
            setChecked={setChecked}
            switchOn={switchOn}
            setSwitchOn={setSwitchOn}
            switchDisabled={switchDisabled}
            setSwitchDisabled={setSwitchDisabled}
          />
          <SigmaEmbed page={page} />
        </Flex>
        <FilterSummary page={page} />
      </Flex>
    );
  else {
    return <Dev />;
  }
};
