import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Form, Select, Input, Radio, Button, Spin, Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@aws-amplify/ui-react';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useUnsubscribedVendors } from '../../../../hooks/useUnsubscribedVendors';
import { useSubscribedVendors } from '../../../../hooks/useSubscribedVendors';
import { useVendorGroupCategories } from '../../../../hooks/useVendorGroupCategories';
import { useBrokerAreas } from '../../../../hooks/useBrokerAreas';
import { publishToTables } from './onSubmitHandler';
import { getNewValues, getValidSelections } from '../../../../utils/helpers';
import { AmplifyAlertBanner } from '../../../../components/AmplifyAlertBanner';
import { TreeSelector } from '../../../../components/VendorAdmin/TreeSelectConfig';
import { options } from '../subscriptionOptions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CMS from '../../../../cms/cms.json';
import Loading from '../../../../components/Loading';

export function CreateVendor() {
  // constants
  const WAIT_TIME = 750; // ms to wait for the debounced search

  // navigate hook
  const navigate = useNavigate();

  // state vars
  const [brokerIsChecked, setBrokerIsChecked] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [categoryState, setCategoryState] = useState(null);
  const [customerIsChecked, setCustomerIsChecked] = useState(false);

  // amplify related config items
  const [config, setConfig] = useState({ variation: '', msg: '' });

  // form related items
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [componentDisabled, setComponentDisabled] = useState(false);

  // tree component related items
  const [treeData, setTreeData] = useState(null);
  const [value, setValue] = useState([]); // TreeSelect needs that name
  const [displayedBrokerAreas] = useState([]);

  const [vendorLevel, setVendorLevel] = useState('1');
  const [vendorSearch, setVendorSearch] = useState('');

  // custom hook calls
  const debouncedVendorSearch = useDebounce(vendorSearch, WAIT_TIME);
  const { subscribedVendors, subscribedVendorsLoaded } = useSubscribedVendors(
    null,
    formSubmitted,
  );
  const { unsubscribedVendors, unsubscribedVendorsLoaded } =
    useUnsubscribedVendors(
      brokerIsChecked,
      debouncedVendorSearch,
      formSubmitted,
    );
  const { vendorGroupCategories, vendorGroupCategoriesLoaded } =
    useVendorGroupCategories(selectedVendors);
  const { brokerAreas } = useBrokerAreas(brokerIsChecked, selectedVendors);
  const selectedChildVendors = useRef([]);
  const selectedBrokerAreasRef = useRef([]);
  // vendor list for dropdown
  const vendorList = useMemo(() => {
    if (unsubscribedVendors) {
      const newChildVendors = selectedChildVendors.current;
      const newVendorList = [...unsubscribedVendors];
      const unsubVendorIds = unsubscribedVendors.map((vendor) => vendor.id);
      newChildVendors.forEach((vendor) => {
        const id = vendor?.id;
        if (!unsubVendorIds.includes(id)) {
          newVendorList.unshift(vendor);
        }
      });
      const vendorListOptions = newVendorList.map((vendor) => ({
        label: vendor.id + ' - ' + vendor.label,
        value: vendor.id,
      }));

      return vendorListOptions;
    }
  }, [JSON.stringify(unsubscribedVendors)]);

  const brokerList = useMemo(() => {
    if (
      brokerAreas &&
      brokerAreas.length > 0 &&
      selectedChildVendors.current.length > 0
    ) {
      const newList = [...brokerAreas];
      const brokerAreaIds = brokerAreas.map((area) => area.id);
      displayedBrokerAreas.forEach((area) => {
        if (!brokerAreaIds.includes(area.id)) {
          newList.push(area);
        }
      });

      return newList;
    } else {
      return [];
    }
  }, [JSON.stringify(brokerAreas), displayedBrokerAreas, brokerIsChecked]);

  // initial form values (property is form item label)
  const initialFormValues = useMemo(() => {
    return {
      vendorRadioSelection: vendorLevel,
      brokerIsChecked: brokerIsChecked,
    };
  }, [brokerIsChecked, vendorLevel]);

  const jwt = useRef();

  // tree component handlers
  const onChange = (newValue) => {
    setValue(newValue);
    setCategoryState(() => {
      if (newValue && newValue.length > 0) {
        return newValue;
      }
    });
  };

  const handleCustomerChange = () => {
    setCustomerIsChecked((val) => !val);
  };

  const handleChangeVendor = (event) => {
    if (event) {
      setSelectedVendors(event);

      // currVendors represents the state of everything a user currently has selected
      // new vendors only contains vendors from the newly fetched unsubscribed vendors list
      const currVendors = selectedChildVendors.current;
      const newVendors = unsubscribedVendors.filter((vendor) =>
        event.includes(vendor.id),
      );
      const currVendorsIds = currVendors.map((vendor) => vendor.id);
      const selectedVendors = getValidSelections(
        event,
        currVendors,
        currVendorsIds,
        newVendors,
      );

      selectedChildVendors.current = selectedVendors;

      setVendorSearch('');
    }
  };

  const onCancel = () => {
    navigate('/manage-vendor');
  };

  const onSubmit = async (values) => {
    setComponentDisabled(true);
    const submittedFormData = await publishToTables(
      jwt,
      brokerAreas,
      value,
      values,
      selectedChildVendors.current,
      vendorGroupCategories,
    );

    if (submittedFormData.status) {
      setTreeData(null);
      setConfig({ variation: 'success', msg: submittedFormData.msg });
    } else {
      setConfig({ variation: 'error', msg: submittedFormData.msg });
    }

    form.resetFields();
    setTreeData(null);
    setFormSubmitted((bool) => !bool);
    setComponentDisabled(false);
    selectedBrokerAreasRef.current = [];
    setValue([]);
    form.setFieldsValue({ Area: [], value: [] });
    setSelectedVendors([]);
    selectedChildVendors.current = [];
    form.setFieldsValue({ 'Child Vendors': [], value: [] });
    setBrokerIsChecked(false);
    setCustomerIsChecked(false);
  };

  useEffect(() => {
    if (selectedVendors.length == 0) {
      selectedBrokerAreasRef.current = [];
      setValue([]);
      form.setFieldsValue({ Area: [], value: [] });
    }
  }, [JSON.stringify(selectedVendors)]);

  useEffect(() => {
    if (brokerAreas && brokerAreas.length > 0) {
      const newBroker = brokerAreas.filter((area) =>
        brokerAreas.includes(area.id),
      );
      selectedBrokerAreasRef.current = newBroker;
    }
  }, [JSON.stringify(brokerAreas)]);

  useEffect(() => {
    if (vendorLevel === '3' && categoryState) {
      setValue(categoryState);
    } else {
      setValue([]);
    }
  }, [vendorLevel, categoryState]);

  useEffect(() => {
    setCategoryState((cats) => {
      let newCategoryState = [];
      if (cats && cats.length > 0) {
        newCategoryState = getNewValues(vendorGroupCategories, cats);
      }
      setValue(newCategoryState);
      return newCategoryState;
    });
  }, [vendorGroupCategories]);

  useEffect(() => {
    // setFieldValue and setFieldsValue are different methods **
    form.setFieldsValue({ value: value });
  }, [form, value]);

  useEffect(() => {
    form.setFieldsValue({ Area: selectedBrokerAreasRef.current });
  }, [JSON.stringify(brokerList)]);

  return subscribedVendorsLoaded ? (
    <div id="create-vendor">
      <h2 className="page-title">
        {CMS.pages.find((o) => o.name === 'createVendor')?.title}
      </h2>

      <AmplifyAlertBanner isDismissible={true} config={config} />

      <Form
        className="add-vendor-form"
        size="small"
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialFormValues}
      >
        <div className="attribute-box">
          <div className="attribute-box-headers">
            <Form.Item
              name="Vendor Group Name"
              label="Vendor Group Name:"
              required
              rules={[
                { required: true },
                {
                  message: 'Enter Unique Vendor Group Name.',
                  validator: (_, value) => {
                    if (
                      subscribedVendors
                        .map((vendor) => vendor.label)
                        .includes(value.toUpperCase())
                    ) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input className="input-vendor-group-name" />
            </Form.Item>

            <Form.Item
              name="Child Vendors"
              label="Child Vendors:"
              required
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                disabled={componentDisabled}
                searchValue={vendorSearch}
                className="select-vendors"
                mode="multiple"
                popupClassName="select-vendors-popup"
                onSearch={(value) => setVendorSearch(value)}
                onChange={handleChangeVendor}
                notFoundContent={null}
                loading={!unsubscribedVendorsLoaded}
                autoClearSearchValue={false}
                suffixIcon={
                  !unsubscribedVendorsLoaded ? (
                    <Spin className="custom-spin" size="small" />
                  ) : null
                }
                value={selectedChildVendors.current}
                options={vendorList}
                filterOption={(value, option) =>
                  option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </div>

          <div className="attribute-box-fields">
            <Flex direction={'row'} width={'100%'}>
              <Flex
                direction={'column'}
                width={'50%'}
                justifyContent={'center'}
              >
                <Form.Item
                  name="Primary Contact Name"
                  label="Primary Contact Name:"
                  required
                  rules={[{ required: true }]}
                  labelCol={{ span: 8 }}
                >
                  <Input
                    className="contact-input"
                    disabled={componentDisabled}
                  />
                </Form.Item>

                <Form.Item
                  name="Primary Contact Email"
                  label="Primary Contact Email:"
                  required
                  rules={[{ required: true }]}
                  labelCol={{ span: 8 }}
                >
                  <Input
                    className="contact-input"
                    disabled={componentDisabled}
                  />
                </Form.Item>

                <Form.Item
                  name="Primary Contact Phone"
                  label="Primary Contact Phone:"
                  labelCol={{ span: 8 }}
                >
                  <Input
                    className="contact-input"
                    disabled={componentDisabled}
                  />
                </Form.Item>
              </Flex>

              <Flex direction={'column'} width={'50%'}>
                <Form.Item
                  name="Secondary Contact Name"
                  label="Secondary Contact Name:"
                  labelCol={{ span: 7 }}
                >
                  <Input
                    className="contact-input"
                    disabled={componentDisabled}
                  />
                </Form.Item>

                <Form.Item
                  name="Secondary Contact Email"
                  label="Secondary Contact Email:"
                  labelCol={{ span: 7 }}
                >
                  <Input
                    className="contact-input"
                    disabled={componentDisabled}
                  />
                </Form.Item>

                <Form.Item
                  name="Secondary Contact Phone"
                  label="Secondary Contact Phone:"
                  labelCol={{ span: 7 }}
                >
                  <Input
                    className="contact-input"
                    disabled={componentDisabled}
                  />
                </Form.Item>
              </Flex>
            </Flex>

            <Form.Item name="vendorRadioSelection" label="Vendor Level:">
              <Radio.Group
                disabled={componentDisabled}
                options={options}
                onChange={(vendorLevel) =>
                  setVendorLevel(vendorLevel?.target?.value)
                }
                value={vendorLevel}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            {vendorLevel === '3' ? (
              <Form.Item label="Customer?:" name="isCustomer">
                <Switch
                  className="period-switch-div"
                  onChange={handleCustomerChange}
                  checked={customerIsChecked}
                  size="large"
                />
              </Form.Item>
            ) : null}

            <Form.Item
              label="Merch Categories:"
              name="value"
              required
              rules={[{ required: vendorLevel === '3' }]}
            >
              <div className="form-item-custom">
                <TreeSelector
                  treeData={treeData}
                  setTreeData={setTreeData}
                  value={value}
                  setValue={setValue}
                  categoriesList={vendorGroupCategories}
                  vendorLevel={vendorLevel}
                  onChange={onChange}
                  selectedChildVendors={selectedVendors}
                  loaded={vendorGroupCategoriesLoaded}
                  disabled={componentDisabled}
                />
              </div>
            </Form.Item>

            <Form.Item
              name="Category Manager"
              label="Category Manager:"
              required
              rules={[{ required: true }]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              name="Category Director"
              label="Category Director:"
              required
              rules={[{ required: true }]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>
          </div>
        </div>

        <div className="edit-user-buttons">
          <Form.Item>
            <Button
              className="cancel-button"
              onClick={onCancel}
              disabled={componentDisabled}
            >
              <div className="button-items">
                {CMS.pages.find((o) => o.name === 'cancel')?.title}
                <CloseRoundedIcon className="cancel-icon" />
              </div>
            </Button>

            <Button
              type="primary"
              className="save-button"
              htmlType="submit"
              disabled={componentDisabled}
            >
              <div className="button-items">
                {CMS.pages.find((o) => o.name === 'save')?.title}
                <SaveOutlinedIcon className="save-icon" />
              </div>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  ) : (
    <div className="center-loading">
      <Loading />
    </div>
  );
}
