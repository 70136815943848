import React from 'react';

export default function SigmaIframe(props) {
  return (
    <div className="full-div">
      <iframe
        ref={props.iframeRef}
        id={props.id}
        src={props.src}
        title={props.page}
        className="sigma-iframe"
      />
    </div>
  );
}
