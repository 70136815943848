import React from 'react';
import { Button } from '@aws-amplify/ui-react';
import { ReactComponent as AzureAD } from '../../assets/azure-active-directory.svg';
import { Auth } from 'aws-amplify';

export const SignInHeader = () => {
  return (
    <>
      <h2 className="login-header">
        WELCOME TO THE WORLD OF DATA-DRIVEN POSSIBILITIES!
      </h2>
      <Button
        className="federated-sign-in-btn"
        onClick={async () => await handleFederatedSignIn()}
      >
        <AzureAD className="azure-logo" />
        USF Employee Sign In
      </Button>
      <div className="login-div-separator">
        <div className="left-separator-line" />
        <div>
          <p className="separator-text">or</p>
        </div>
        <div className="right-separator-line" />
      </div>
    </>
  );
};

export const handleFederatedSignIn = async () => {
  try {
    await Auth.federatedSignIn({ customProvider: 'AzureAD' }).then(async () => {
      await Auth.currentAuthenticatedUser();
    });
  } catch (error) {
    console.error(error);
  }
};
