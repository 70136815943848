import { useState, useEffect } from 'react';
import { sortObject } from '../utils/helpers';
import vendorsService from '../axios/vendorsService';

export const useUnsubscribedVendors = (
  isBroker,
  vendorNameFragment = null,
  formSubmitted,
) => {
  const [unsubscribedVendors, setUnsubscribedVendors] = useState(null);
  const [unsubscribedVendorsLoaded, setUnsubscribedVendorsLoaded] =
    useState(false);

  useEffect(() => {
    setUnsubscribedVendorsLoaded(false);
    setUnsubscribedVendors(null);

    const fetchUnsubVendors = async () => {
      const unsubVendorsResponse =
        await vendorsService.fetchUnsubscribedVendors(
          null,
          isBroker,
          vendorNameFragment,
        );
      const { data } = unsubVendorsResponse;
      let vendorData = [];
      if (data) {
        vendorData = data.map((vendor) => {
          return {
            label: vendor.vendorName,
            id: vendor.vendorId,
          };
        });
        vendorData = vendorData.sort(sortObject);
      }
      setUnsubscribedVendors(vendorData);
      setUnsubscribedVendorsLoaded(true);
    };

    fetchUnsubVendors();
  }, [isBroker, vendorNameFragment, formSubmitted]);

  return { unsubscribedVendors, unsubscribedVendorsLoaded };
};
