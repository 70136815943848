import React from 'react';
import featureToggle from '../../../featureToggle.json';
import Dev from '../../dev/Dev';
import { Flex } from '@aws-amplify/ui-react';
import { useContext } from 'react';
import { useIdleTimer } from '../../../utils/IdleTimer';
import { FiltersContext } from '../../../components/Navigation/Routes';
import { Button } from '@mui/material';
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';

export const Forecasts = ({ page }) => {
  const context = useContext(FiltersContext);
  const { channel } = context;

  const environment = process.env.REACT_APP_ENVIRONMENT;

  useIdleTimer(channel);

  if (featureToggle[environment][page]) {
    return (
      <Flex
        direction={'column'}
        width={'100%'}
        height={'100%'}
        overflow={'hidden'}
        paddingTop={'12px'}
      >
        <Flex direction={'row'} style={{ marginInlineStart: '20px' }}>
          <Button className="information-buttons">Detail Reports</Button>
        </Flex>

        <SigmaEmbed page={'sif'} />
      </Flex>
    );
  } else {
    return <Dev />;
  }
};
