import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function Dev() {
  return (
    <div className="center-title">
      <h2>UNDER CONSTRUCTION</h2>
      <ConstructionIcon fontSize="large" />
    </div>
  );
}
