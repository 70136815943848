/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishResult = /* GraphQL */ `
  mutation PublishResult($result: ResultInput) {
    publishResult(result: $result) {
      id
      status
      listings
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $vendorGroupNumber: Int!
    $isBroker: Boolean!
    $associatedBroker: String!
    $phone: String
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      vendorGroupNumber: $vendorGroupNumber
      isBroker: $isBroker
      associatedBroker: $associatedBroker
      phone: $phone
    )
  }
`;

export const createInternalUser = /* GraphQL */ `
  mutation CreateInternalUser(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    createInternalUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
    )
  }
`;

export const editUser = /* GraphQL */ `
  mutation EditUser(
    $mail: String!
    $newMail: String!
    $vendorGroupNumber: Int!
    $firstName: String!
    $lastName: String!
    $phone: String
    $associatedBroker: String!
  ) {
    editUser(
      mail: $mail
      newMail: $newMail
      vendorGroupNumber: $vendorGroupNumber
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      associatedBroker: $associatedBroker
    )
  }
`;

export const insertBroker = /* GraphQL */ `
  mutation InsertBroker($broker_group_name: String!) {
    insertBroker(broker_group_name: $broker_group_name)
  }
`;

export const insertBrokerAreas = /* GraphQL */ `
  mutation InsertBrokerAreas(
    $area_names: String!
    $vendor_group_name: String!
  ) {
    insertBrokerAreas(
      area_names: $area_names
      vendor_group_name: $vendor_group_name
    )
  }
`;

export const updateBroker = /* GraphQL */ `
  mutation UpdateBroker(
    $broker_group_name: String!
    $broker_group_number: Int!
  ) {
    updateBroker(
      broker_group_name: $broker_group_name
      broker_group_number: $broker_group_number
    )
  }
`;

export const updateBrokerAreas = /* GraphQL */ `
  mutation UpdateBrokerAreas(
    $area_names: String!
    $broker_group_name: String!
  ) {
    updateBrokerAreas(
      area_names: $area_names
      broker_group_name: $broker_group_name
    )
  }
`;

export const insertParentVendor = /* GraphQL */ `
  mutation InsertParentVendor(
    $vendorRoleName: String
    $vendorGroupName: String!
    $primaryContactName: String!
    $primaryContactEmail: String!
    $primaryContactPhone: String!
    $secondaryContactName: String!
    $secondaryContactEmail: String!
    $secondaryContactPhone: String!
    $categoryDirector: String!
    $categoryManager: String!
    $subscriptionId: String!
    $subscriptionName: String!
    $isBroker: String!
    $isCustomer: String!
  ) {
    insertParentVendor(
      vendorRoleName: $vendorRoleName
      vendorGroupName: $vendorGroupName
      primaryContactName: $primaryContactName
      primaryContactEmail: $primaryContactEmail
      primaryContactPhone: $primaryContactPhone
      secondaryContactName: $secondaryContactName
      secondaryContactEmail: $secondaryContactEmail
      secondaryContactPhone: $secondaryContactPhone
      categoryDirector: $categoryDirector
      categoryManager: $categoryManager
      subscriptionId: $subscriptionId
      subscriptionName: $subscriptionName
      isBroker: $isBroker
      isCustomer: $isCustomer
    )
  }
`;

export const insertChildVendor = /* GraphQL */ `
  mutation InsertChildVendor(
    $childVendors: String!
    $vendorGroupName: String!
  ) {
    insertChildVendor(
      childVendors: $childVendors
      vendorGroupName: $vendorGroupName
    )
  }
`;

export const updateChildVendors = /* GraphQL */ `
  mutation UpdateChildVendors(
    $childVendors: String!
    $vendorGroupName: String!
    $vendorGroupNumber: Int!
  ) {
    updateChildVendors(
      childVendors: $childVendors
      vendorGroupName: $vendorGroupName
      vendorGroupNumber: $vendorGroupNumber
    )
  }
`;

export const insertVendorCategory = /* GraphQL */ `
  mutation InsertVendorCategory(
    $vendorGroupName: String!
    $childrenCategoryNames: String!
  ) {
    insertVendorCategory(
      vendorGroupName: $vendorGroupName
      childrenCategoryNames: $childrenCategoryNames
    )
  }
`;

export const updateVendorCategories = /* GraphQL */ `
  mutation UpdateVendorCategories(
    $vendorGroupName: String!
    $childrenCategoryNames: String!
    $vendorGroupNumber: Int!
  ) {
    updateVendorCategories(
      vendorGroupName: $vendorGroupName
      childrenCategoryNames: $childrenCategoryNames
      vendorGroupNumber: $vendorGroupNumber
    )
  }
`;

export const updateParentVendor = /* GraphQL */ `
  mutation UpdateParentVendor(
    $vendorGroupNumber: Int!
    $vendorGroupName: String!
    $primaryContactName: String!
    $primaryContactEmail: String!
    $primaryContactPhoneNumber: String!
    $secondaryContactName: String!
    $secondaryContactEmail: String!
    $secondaryContactPhoneNumber: String!
    $categoryDirector: String!
    $categoryManager: String!
    $subscriptionId: String!
    $subscriptionName: String!
    $status: Boolean!
    $isCustomer: String!
  ) {
    updateParentVendor(
      vendorGroupNumber: $vendorGroupNumber
      vendorGroupName: $vendorGroupName
      primaryContactName: $primaryContactName
      primaryContactEmail: $primaryContactEmail
      primaryContactPhoneNumber: $primaryContactPhoneNumber
      secondaryContactName: $secondaryContactName
      secondaryContactEmail: $secondaryContactEmail
      secondaryContactPhoneNumber: $secondaryContactPhoneNumber
      categoryDirector: $categoryDirector
      categoryManager: $categoryManager
      subscriptionId: $subscriptionId
      subscriptionName: $subscriptionName
      status: $status
      isCustomer: $isCustomer
    )
  }
`;

export const insertUserFilters = /* GraphQL */ `
  mutation InsertUserFilters(
    $name: String!
    $filters: String!
    $reportType: String!
  ) {
    insertUserFilters(name: $name, filters: $filters, reportType: $reportType)
  }
`;

export const deleteUserFilters = /* GraphQL */ `
  mutation DeleteUserFilters($name: String!, $reportType: String!) {
    deleteUserFilters(name: $name, reportType: $reportType)
  }
`;

export const manageUserStatus = /* GraphQL */ `
  mutation ManageUserStatus($mail: String, $action: String) {
    manageUserStatus(mail: $mail, action: $action)
  }
`;

export const manageInternalAdmin = /* GraphQL */ `
  mutation ManageInternalAdmin($email: String, $action: String) {
    manageInternalAdmin(email: $email, action: $action)
  }
`;

export const manageVendorStatus = /* GraphQL */ `
  mutation ManageVendorStatus($vendorGroupNumber: Int, $action: String) {
    manageVendorStatus(vendorGroupNumber: $vendorGroupNumber, action: $action)
  }
`;

export const duplicateUserFilters = /* GraphQL */ `
  mutation DuplicateUserFilters($reportType: String!, $shareCode: String!) {
    duplicateUserFilters(shareCode: $shareCode, reportType: $reportType)
  }
`;
