import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './components/Wrappers/ScrollToTop';
import Authenticator from './Authenticator';
import AWSAuthenticator from './components/AWSAuthenticator';
import featureToggle from './featureToggle.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
const passwordlessAuthEnabled =
  featureToggle[process.env.REACT_APP_ENVIRONMENT]['passwordless-auth'];

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      {passwordlessAuthEnabled ? <Authenticator /> : <AWSAuthenticator />}
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
