/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIsUserAuthorized = /* GraphQL */ `
  query IsUserAuthorizedQuery {
    isUserAuthorized
  }
`;

export const getSnowflakeInfo = /* GraphQL */ `
  query GetSnowflakeInfo {
    getSnowflakeInfo {
      data
    }
  }
`;

export const getSigmaUrl = /* GraphQL */ `
  query GetSigmaUrl(
    $submenu: String
    $filters: AWSJSON
    $sfRole: String
    $impersonate: Boolean
  ) {
    getSigmaUrl(
      submenu: $submenu
      filters: $filters
      sfRole: $sfRole
      impersonate: $impersonate
    ) {
      embedding_url
    }
  }
`;

export const getSnowflakeDataForPlugin = /* GraphQL */ `
  query GetSnowflakeDataForPlugin(
    $dimensions: [String]
    $metrics: [String]
    $currentTimeframe: [String]
    $previousTimeframe: [String]
    $filters: AWSJSON
    $role: String
  ) {
    getSnowflakeDataForPlugin(
      dimensions: $dimensions
      metrics: $metrics
      currentTimeframe: $currentTimeframe
      previousTimeframe: $previousTimeframe
      filters: $filters
      role: $role
    ) {
      rowsReturned
      sfData
    }
  }
`;
export const search = /* GraphQL */ `
  query Search($text: String) {
    search(text: $text) {
      id
      status
      listings
    }
  }
`;

export const getImpersonatorVendors = /* GraphQL */ `
  query GetImpersonatorVendors {
    getImpersonatorVendors {
      rowsReturned
      data {
        vendorGroupNumber
        vendorGroupName
        subscriptionName
        subscriptionId
        status
        isBroker
        isCustomer
        vendorRole
      }
    }
  }
`;

export const getSubscribedVendors = /* GraphQL */ `
  query GetSubscribedVendors {
    getSubscribedVendors {
      rowsReturned
      data {
        vendorGroupNumber
        vendorGroupName
        subscriptionName
        subscriptionId
        status
        createdAt
        isBroker
      }
    }
  }
`;

export const getChildrenVendors = /* GraphQL */ `
  query GetChildrenVendors($vendorGroupNumber: Int) {
    getChildrenVendors(vendorGroupNumber: $vendorGroupNumber) {
      rowsReturned
      data {
        vendorName
        vendorGroupNumber
        vendorNumber
      }
    }
  }
`;

export const getSubscribedVendorsAtrributes = /* GraphQL */ `
  query GetSubscribedVendors($vendorGroupNumber: Int) {
    getSubscribedVendors(vendorGroupNumber: $vendorGroupNumber) {
      rowsReturned
      data {
        vendorGroupNumber
        vendorGroupName
        primaryContactName
        primaryContactEmail
        primaryContactPhoneNumber
        secondaryContactName
        secondaryContactEmail
        secondaryContactPhoneNumber
        categoryManager
        categoryDirector
        subscriptionName
        subscriptionId
        status
        isBroker
        isCustomer
        createdAt
      }
    }
  }
`;

export const getSubscribedVendorAdminAtrributes = /* GraphQL */ `
  query GetSubscribedVendors {
    getSubscribedVendors {
      rowsReturned
      data {
        vendorGroupNumber
        vendorGroupName
        vendorRole
        subscriptionName
        subscriptionId
        isBroker
        isCustomer
      }
    }
  }
`;

export const getSubscribedVendorRoles = /* GraphQL */ `
  query getSubscribedVendorRoles {
    getSubscribedVendorRoles {
      rowsReturned
      data {
        vendorGroupName
        vendorRole
      }
    }
  }
`;

export const getUsersList = /* GraphQL */ `
  query GetUsersList {
    getUsersList {
      data {
        firstName
        active
        lastName
        mail
        phone
        vendorName
        vendorRole
        createdAt
        isBroker
        associatedBroker
      }
    }
  }
`;

export const getUnsubscribedVendors = /* GraphQL */ `
  query GetUnsubscribedVendors($vendorName: String, $isBroker: Boolean) {
    getUnsubscribedVendors(vendorName: $vendorName, isBroker: $isBroker) {
      rowsReturned
      data {
        vendorId
        vendorName
      }
    }
  }
`;

export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($mail: String!) {
    getUserInfo(mail: $mail) {
      data {
        firstName
        lastName
        mail
        phone
        vendorName
        vendorGroupNumber
      }
    }
  }
`;

export const getVendorMerchCategories = /* GraphQL */ `
  query GetVendorMerchCategories($vendorIds: String) {
    getVendorMerchCategories(vendorIds: $vendorIds) {
      data {
        childCategory
        parentCategory
        childCategoryId
      }
    }
  }
`;

export const getVendorGroupMerchCategories = /* GraphQL */ `
  query GetVendorGroupMerchCategories($vendorGroupNumber: Int) {
    getVendorGroupMerchCategories(vendorGroupNumber: $vendorGroupNumber) {
      rowsReturned
      data {
        merchCategoryId
        merchCategoryName
      }
    }
  }
`;

export const getInternalUsersList = /* GraphQL */ `
  query GetInternalUsersList {
    getInternalUsersList {
      data {
        username
        lastName
        mail
        phone
        active
        vendorName
      }
    }
  }
`;

export const getS3Documents = /* GraphQL */ `
  query GetS3Documents($folder: String, $type: String) {
    getS3Documents(folder: $folder, type: $type)
  }
`;

export const getNavigationForUser = /* GraphQL */ `
  query GetNavigationForUser($userContext: String) {
    getNavigationForUser(userContext: $userContext) {
      navItems {
        name
        order
        path
        title
        children {
          name
          title
          path
          order
        }
      }
    }
  }
`;

export const getUserFilters = /* GraphQL */ `
  query GetUserFilters($reportType: String!) {
    getUserFilters(reportType: $reportType)
  }
`;

export const getUserShareCodes = /* GraphQL */ `
  query GetUserShareCodes($reportType: String!) {
    getUserShareCodes(reportType: $reportType)
  }
`;

export const getSubscriptionId = /* GraphQL */ `
  query GetSubId {
    getSubscriptionId
  }
`;

export const getPeppers = /* GraphQL */ `
  query GetPeppers($vendor: String, $week: String, $roleInformation: String) {
    getPeppers(
      vendor: $vendor
      week: $week
      roleInformation: $roleInformation
    ) {
      presignedURLFR
      presignedURLOT
      presignedURLWS
    }
  }
`;

export const getProducePeppers = /* GraphQL */ `
  query GetProducePeppers(
    $vendor: String
    $week: String
    $asysCode: String
    $asysCodeDesc: String
    $region: String
    $area: String
    $market: String
    $roleInformation: String
  ) {
    getProducePeppers(
      vendor: $vendor
      week: $week
      asysCode: $asysCode
      asysCodeDesc: $asysCodeDesc
      region: $region
      area: $area
      market: $market
      roleInformation: $roleInformation
    ) {
      presignedURLFR
      presignedURLWS
    }
  }
`;

export const getBrokerAreas = /* GraphQL */ `
  query GetBrokerAreas($childVendors: String) {
    getBrokerAreas(childVendors: $childVendors) {
      rowsReturned
      data {
        area_id
        area_nm
      }
    }
  }
`;

export const getSelectedBrokerAreas = /* GraphQL */ `
  query GetSelectedBrokerAreas(
    $vendorGroupNumber: Int
    $brokerGroupName: String
  ) {
    getSelectedBrokerAreas(
      vendorGroupNumber: $vendorGroupNumber
      brokerGroupName: $brokerGroupName
    ) {
      rowsReturned
      data {
        area_id
        area_name
        vendor_group_number
        vendor_group_name
      }
    }
  }
`;

export const getVendorsForBrokers = /* GraphQL */ `
  query getVendorsForBrokers {
    getVendorsForBrokers {
      data {
        vendor_group_number
        vendor_group_name
      }
    }
  }
`;

export const getBrokerManagerData = /* GraphQL */ `
  query getBrokerManagerData {
    getBrokerManagerList {
      data {
        broker_area_count
        broker_group_name
        broker_group_number
        broker_is_active
        broker_vendor_count
      }
    }
  }
`;

export const getBrokerAvailableAreas = /* GraphQL */ `
  query getBrokerAvailableAreas(
    $brokerGroupName: String
    $vendorGroupNumber: Int
  ) {
    getBrokerAvailableAreas(
      brokerGroupName: $brokerGroupName
      vendorGroupNumber: $vendorGroupNumber
    ) {
      data {
        vendor_group_number
        area_name
        area_id
      }
    }
  }
`;

export const getBrokerInformation = /* GraphQL */ `
  query GetSelectedBrokerAreas($vendorGroupNumber: Int) {
    getSelectedBrokerAreas(vendorGroupNumber: $vendorGroupNumber) {
      rowsReturned
      data {
        area_name
        vendor_group_number
        vendor_number
      }
    }
  }
`;

export const getBrokerList = /* GraphQL */ `
  query GetBrokerList {
    getBrokerList {
      rowsReturned
      data {
        broker_group_name
        broker_group_number
      }
    }
  }
`;

export const getBrokerVendors = /* GraphQL */ `
  query GetBrokerVendors($is_broker: Boolean, $broker: String) {
    getBrokerVendors(is_broker: $is_broker, broker: $broker) {
      data {
        vendor_group_name
        broker
        vendor_role_name
        vendor_group_number
        is_broker
      }
    }
  }
`;
