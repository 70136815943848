import { Auth, Hub } from 'aws-amplify';
import usersService from '../axios/usersService';

export function authorizeUser(setIsAdmin, setRoleError, setViewType) {
  Auth.currentSession()
    .then(async (session) => {
      // see https://github.com/aws-amplify/amplify-js/issues/4751#issuecomment-1659705664 for why the token is built this way
      const userRef = session.getIdToken().payload;
      const isInternalUser =
        userRef['cognito:groups'] &&
        userRef['cognito:groups'].includes('Internal');

      if (isInternalUser) {
        setIsAdmin(true);
        setViewType('insights');
      } else if (
        userRef.identities &&
        userRef.identities[0].providerName === 'AzureAD'
      ) {
        // internal user first time logged in
        setIsAdmin(true);
        // This would ideally live in the BFF
        await usersService.createInternalUser(
          userRef.email,
          userRef.name,
          userRef.family_name,
        );

        //this refreshes the user session so that groups showup correctly
        const currentUser = await Auth.currentAuthenticatedUser();
        const userSession = currentUser.getSignInUserSession();
        const refreshToken = userSession.getRefreshToken();
        await currentUser.refreshSession(refreshToken, async (err, session) => {
          await currentUser.setSignInUserSession(session);
        });
      } else if (userRef['cognito:groups'].includes('Vendor')) {
        // user is a vendor logic here if needed
      } else {
        // add error handling
      }

      // capture email and use in service call to lambda ---> to get associated Snowflake Role
      const userIsAuthorized = await usersService.getIsUserAuthorized();
      if (!userIsAuthorized) {
        setIsAdmin(false);
        setRoleError(true);
        return;
      }
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      Hub.dispatch('refresh', {
        event: 'AuthorizationRefresh',
        message: 'Authorization Refresh Executed',
      });
    });
}

export function logOut(channel) {
  channel.postMessage('logout');
  Auth.signOut();
}

export const getFirstAndLastFromArray = (array) => {
  if (!Array.isArray(array) || array.length === 0) {
    return [null, null];
  }

  return [array[0], array[array.length - 1]];
};

// functions handle altering properties stored in filter state object
export function getToggledFilters(selectedToggle, filters) {
  filters[`previous-timeframe-${selectedToggle}`] =
    filters['previous-timeframe'];
  filters[`current-timeframe-${selectedToggle}`] = filters['current-timeframe'];

  delete filters['previous-timeframe'];
  delete filters['current-timeframe'];

  return filters;
}

export function removeTags(selectedToggle, toggledFilters, page) {
  const detaggedFilters = {};
  Object.keys(toggledFilters).forEach((key) => {
    const tagToRemove = `-${selectedToggle}`;
    const detaggedKey = key.replace(tagToRemove, '');
    if (page === 'customer-data' || page === 'purchase') {
      const pageDetaggedKey =
        page === 'customer-data'
          ? detaggedKey.replace('-customer', '')
          : detaggedKey.replace('-purchasing', '');
      detaggedFilters[pageDetaggedKey] = toggledFilters[key];
    } else {
      detaggedFilters[detaggedKey] = toggledFilters[key];
    }
  });
  return detaggedFilters;
}

export function formatFilters(filters) {
  const displayedFilters = Object.entries(filters).map(([key, val]) => {
    // split key on '-' and then capitalize everything

    const dispKey = key
      .split('-')
      .map((wordInKey) => {
        const firstLetter = wordInKey.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase();
        const remainingLetters = wordInKey.slice(1);
        const capitalizedWord = firstLetterCap + remainingLetters;

        return capitalizedWord;
      })
      .join(' ');

    const dispValArr = val.split(',').map((encodedVal) => {
      return decodeURIComponent(encodedVal);
    });

    return [dispKey, dispValArr];
  });

  return displayedFilters;
}

export function formatVendorIds(vendorIds) {
  let formattedIds = '';
  for (let i = 0; i < vendorIds.length; i++) {
    if (i !== vendorIds.length - 1) {
      formattedIds += `${vendorIds[i].vendorId},`;
    } else {
      formattedIds += `${vendorIds[i].vendorId}`;
    }
  }

  return formattedIds;
}

// sorts array of objects by "label" property
export const sortObject = (a, b) => {
  // Compare the "label" property of each object
  const labelA = a.label.toUpperCase(); // Convert to uppercase for case-insensitive sorting
  const labelB = b.label.toUpperCase();

  if (labelA < labelB) {
    return -1; // Negative value means "a" comes before "b"
  } else if (labelA > labelB) {
    return 1; // Positive value means "b" comes before "a"
  } else {
    return 0; // Return 0 for items that are equal
  }
};

// functions to aid in common processes between create and edit vendor
export const getNewCategories = (newValue, categoriesList) => {
  const newCategories = [];
  if (categoriesList && categoriesList.length !== 0) {
    categoriesList.forEach((categories) => {
      if (newValue.includes(categories.parent)) {
        newCategories.push(...categories.children);
      } else {
        categories.children.forEach((child) => {
          if (newValue.includes(child)) {
            newCategories.push(child);
          }
        });
      }
    });
  }

  return newCategories;
};

export const getParentsAndCategoriesArrays = (vendorCategoriesInfo) => {
  const parentsArray = [];
  const categoriesList = [];

  if (vendorCategoriesInfo) {
    vendorCategoriesInfo.forEach((category) => {
      const parent = category.parentCategory;
      const children = category.childCategory;

      if (!parentsArray.includes(parent)) {
        parentsArray.push(parent);
        categoriesList.push({ parent: parent, children: [children] });
      } else {
        const parentPosition = parentsArray.indexOf(parent);
        categoriesList[parentPosition]['children'].push(children);
      }
    });
  }

  return categoriesList;
};

export const formatCategoriesList = (categoriesList, vendorLevel) => {
  if (!categoriesList || categoriesList.length === 0) return;

  let tmpTreeData = [];
  categoriesList.forEach((category) => {
    let childrenTreeData = [];
    const parent = category.parent;
    const children = category.children;
    children.forEach((child) => {
      // add merch category id to query and change it value of key property
      childrenTreeData.push({
        title: child,
        value: child,
        key: child,
        disabled: vendorLevel !== '3' ? true : false,
      });
    });
    tmpTreeData.push({
      title: parent,
      value: parent,
      key: parent,
      disabled: vendorLevel !== '3' ? true : false,
      children: childrenTreeData,
    });
  });

  return tmpTreeData;
};

export const getNewValues = (vendorGroupCategories, values) => {
  const parentCategories = [];
  const childCategories = [];

  vendorGroupCategories.map((cat) => parentCategories.push(cat.parent));
  vendorGroupCategories.map((cat) => childCategories.push(...cat.children));

  const newVal = [];
  values.forEach((val) => {
    if (parentCategories.includes(val)) {
      newVal.push(val);
    } else if (childCategories.includes(val)) {
      newVal.push(val);
    }
  });

  return newVal;
};

export const formatVendorData = (selectedVendorsResponse) => {
  let vendorData = [];
  if (selectedVendorsResponse) {
    vendorData = selectedVendorsResponse.map((vendor) => {
      return {
        ...vendor,
        key: vendor.vendorGroupNumber,
        status: vendor.status === 'true' ? 'active' : 'inactive',
      };
    });
  }

  return vendorData;
};

export const getValidSelections = (event, currItems, currItemIds, newItems) => {
  // is our event < selected (removal) || event > selected (add)
  let selectedItems = [...currItems];

  // vendors shrinking || brokers growing

  if (event.length < selectedItems.length) {
    selectedItems.forEach((item) => {
      const id = item?.id;
      if (!event.includes(id)) {
        selectedItems = selectedItems.filter((selectedItem) => {
          return selectedItem.id !== id;
        });
      }
    });
  } else {
    // vendors growing || brokers shrinking
    newItems.forEach((item) => {
      const id = item?.id;
      if (!currItemIds.includes(id)) {
        selectedItems.push(item);
      }
    });
  }

  return selectedItems;
};

export const getWorksheetControlConfigs = (
  page,
  viewType,
  pluginConfig,
  roleInformation,
  detaggedFilters,
) => {
  let roleControl = '';
  let newFilters = {};

  switch (page) {
    case 'home':
      newFilters = {
        'role-home': encodeURIComponent(JSON.stringify(roleInformation)),
        'previous-timeframe-basic-graphs': encodeURIComponent(
          JSON.stringify({
            'previous-timeframe': detaggedFilters['previous-timeframe'],
          }),
        ),
        'current-timeframe-basic-graphs': encodeURIComponent(
          JSON.stringify({
            'current-timeframe': detaggedFilters['current-timeframe'],
          }),
        ),
      };
      break;
    case 'cdq':
      roleControl =
        viewType === 'basic' ? 'role-information-basic' : 'role-information-1';
      newFilters = {
        [roleControl]: encodeURIComponent(JSON.stringify(roleInformation)),
      };
      break;
    case 'vr':
      roleControl =
        viewType === 'basic' ? 'role-control-vr-basic' : 'role-control-vr';
      newFilters = {
        [roleControl]: encodeURIComponent(JSON.stringify(roleInformation)),
        config: encodeURIComponent(JSON.stringify(pluginConfig)),
      };
      break;
    case 'fsr':
      if (viewType === 'basic') {
        newFilters = {
          'role-control-sp': encodeURIComponent(
            JSON.stringify(roleInformation),
          ),
          'st-config': encodeURIComponent(JSON.stringify(pluginConfig)),
        };
      } else {
        newFilters = {
          'role-control-sp-insights': encodeURIComponent(
            JSON.stringify(roleInformation),
          ),
          'st-config': encodeURIComponent(JSON.stringify(pluginConfig)),
        };
      }
      break;
    case 'weekly':
      roleControl =
        viewType === 'basic' ? 'role-control-wi-basic' : 'role-control-wi';
      newFilters = {
        [roleControl]: encodeURIComponent(JSON.stringify(roleInformation)),
        config: encodeURIComponent(JSON.stringify(pluginConfig)),
      };
      break;
    case 'purchase':
      newFilters = {
        'role-information-purchasing': encodeURIComponent(
          JSON.stringify(roleInformation),
        ),
        'current-timeframe-basic-graphs': encodeURIComponent(
          JSON.stringify({
            'current-timeframe': detaggedFilters['current-timeframe'],
          }),
        ),
      };
      break;
    case 'sales':
      newFilters = {
        'current-timeframe-basic-graphs': encodeURIComponent(
          JSON.stringify({
            'current-timeframe': detaggedFilters['current-timeframe'],
          }),
        ),
      };
      break;
    case 'customer-data':
      roleControl = 'role-information-customer';
      newFilters = {
        [roleControl]: encodeURIComponent(JSON.stringify(roleInformation)),
        'config-cd': encodeURIComponent(JSON.stringify(pluginConfig)),
      };
      break;
    default:
  }

  return newFilters;
};

export const getFormattedFilters = (detaggedFilters) => {
  let formattedFilters = {};
  Object.keys(detaggedFilters).forEach((key) => {
    if (!['previous-timeframe', 'current-timeframe'].includes(key)) {
      // format key
      const reformattedKey =
        key === 'EB-MB-Flag' ? 'EB-MB Flag' : key.replace(/-/g, ' ');

      let reformattedValue = detaggedFilters[key];

      // format values (replace , with | and add escape char to ')
      reformattedValue = reformattedValue.replace(/,/g, '|');
      reformattedValue = reformattedValue.replace(/%27/g, '%5C%27');

      formattedFilters[key === 'Asys' ? 'ASYS' : reformattedKey] =
        reformattedValue;
    } else {
      formattedFilters[key] = detaggedFilters[key];
    }
  });

  return formattedFilters;
};

export const fetchPeppers = async (sldObject, roleInformation) => {
  const week = sldObject?.week ? sldObject.week : null;
  const vendor = sldObject?.vendor ? sldObject?.vendor : null;
  const peppers = await usersService.getPeppers(vendor, week, roleInformation);

  if (peppers && !peppers.errors) {
    return peppers;
  }
};

export const fetchProducePeppers = async (sipObject, roleInformation) => {
  const week = sipObject?.week ? sipObject.week : null;
  const vendor = sipObject?.vendor ? sipObject?.vendor : null;
  const asysCode = sipObject?.asysCode ? sipObject?.asysCode : null;
  const asysCodeDesc = sipObject?.asysCodeDesc ? sipObject?.asysCodeDesc : null;
  const region = sipObject?.region ? sipObject?.region : null;
  const area = sipObject?.area ? sipObject?.area : null;
  const market = sipObject?.market ? sipObject?.market : null;

  const peppers = await usersService.getProducePeppers(
    vendor,
    week,
    asysCode,
    asysCodeDesc,
    region,
    area,
    market,
    roleInformation,
  );

  if (peppers && !peppers.errors) {
    return peppers;
  }
};

export const postIframeMessage = (sigma_iframe, variables) => {
  sigma_iframe.contentWindow.postMessage(
    {
      type: 'workbook:variables:update',
      variables: variables,
    },
    'https://app.sigmacomputing.com',
  );
};

export const scrollToBottom = (UIElement) => {
  UIElement.scrollTop = UIElement.scrollHeight;
};
