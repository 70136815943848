import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { FaqAccordion } from './FaqAccordion';
import { useDebounce } from '../../hooks/useDebounce';
import SearchIcon from '@mui/icons-material/Search';
import FaqList from './questions.json';

export function FAQs() {
  const [faqSearch, setFaqSearch] = useState('');
  const [faqResults, setFaqResults] = useState(FaqList);
  const debouncedFaqSearch = useDebounce(faqSearch, 750);

  const handleSearch = (faq) => {
    const text = faq.target.value;
    setFaqSearch(text);
  };

  useEffect(() => {
    let filteredFaqs = [];

    if (!debouncedFaqSearch) {
      filteredFaqs = FaqList;
    } else {
      filteredFaqs = FaqList.filter((faq) => {
        const question = faq.QUESTION.toLowerCase();
        return question.includes(debouncedFaqSearch.toLowerCase());
      });
    }

    setFaqResults(filteredFaqs);
  }, [debouncedFaqSearch]);

  return (
    <div className="faq-div">
      <div className="faq-header-div">
        <h2>FAQs</h2>
        <TextField
          id="input-with-icon-textfield"
          label="Search Keyword"
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>

      <hr />

      {faqResults.map((faq) => {
        return (
          <div className="faq-accordion-div" key={faq.ID}>
            <FaqAccordion
              key={faq.ID}
              id={faq.ID}
              question={faq.QUESTION}
              answer={faq.ANSWER}
            />
          </div>
        );
      })}
    </div>
  );
}
