import { useState, useEffect } from 'react';
import {
  formatVendorIds,
  getParentsAndCategoriesArrays,
} from '../utils/helpers';
import vendorsService from '../axios/vendorsService';

export const useVendorGroupCategories = (selectedVendors) => {
  const [vendorGroupCategories, setVendorGroupCategories] = useState(null);
  const [vendorGroupCategoriesLoaded, setVendorGroupCategoriesLoaded] =
    useState(false);

  useEffect(() => {
    setVendorGroupCategoriesLoaded(false);

    const fetchVendorGroupCategories = async () => {
      if (selectedVendors && selectedVendors.length > 0) {
        const vendorIds = selectedVendors.map((vendor) => {
          return { vendorId: vendor };
        });
        const formattedVendorIds = formatVendorIds(vendorIds);
        const categoriesListResponse =
          await vendorsService.fetchVendorCategories(null, formattedVendorIds);
        const { data } = categoriesListResponse;

        const formattedCategories = getParentsAndCategoriesArrays(data);

        setVendorGroupCategories(formattedCategories);
        setVendorGroupCategoriesLoaded(true);
      }
    };

    fetchVendorGroupCategories();
  }, [JSON.stringify(selectedVendors)]);

  return { vendorGroupCategories, vendorGroupCategoriesLoaded };
};
