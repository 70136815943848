import { FiltersContext } from '../components/Navigation/Routes';
import React, { useEffect, useContext } from 'react';
import CustomModal from './CustomModal';
import '../App.css';

export default function AlertImpersonate(props) {
  const context = useContext(FiltersContext);
  const { setImpersonatorInfo } = context;

  const handleVendorChange = (vendor) => {
    props.setSelectedVendor(vendor ? vendor : null);
  };

  useEffect(() => {
    if (!props.isImpersonating) {
      setImpersonatorInfo({});
    }
  }, [props.isImpersonating, setImpersonatorInfo]);

  // we want to render a modal defined here but remove all of the abstraction for updating data from it
  return props.alertImpersonate ? (
    <CustomModal
      selectedVendor={props.selectedVendor}
      handleVendorChange={handleVendorChange}
      handleImpersonating={props.handleImpersonating}
      handleCloseImpersonatorMenu={props.handleCloseImpersonatorMenu}
      handleOpenModal={props.handleOpenModal}
    />
  ) : (
    ''
  );
}
