import { useEffect } from 'react';
import { fetchPeppers } from '../utils/helpers';
import { fetchProducePeppers } from '../utils/helpers';

export const useFetchPeppers = (
  page,
  sldObject,
  setPepper,
  impersonatorInfo,
) => {
  useEffect(() => {
    if (!sldObject) return () => {};

    if (
      (page === 'sld' || page === 'sip') &&
      'week' in sldObject &&
      'vendor' in sldObject
    ) {
      const sfRole = impersonatorInfo ? impersonatorInfo.sfRole : '';
      if (page === 'sip') {
        fetchProducePeppers(sldObject, sfRole).then((pepper) => {
          setPepper(pepper);
        });
      } else {
        fetchPeppers(sldObject, sfRole).then((pepper) => {
          setPepper(pepper);
        });
      }
    }
  }, [JSON.stringify(sldObject)]);
};
