import React from 'react';
import { Table } from 'antd';
import '../../pages/admin/vendor/ManageVendors/ManageVendors.css';
import '../../App.css';

export const DataTable = ({ columns, data, dataLoading, actionLoading }) => {
  return (
    <Table
      className="data-table"
      columns={columns}
      bordered
      size="small"
      dataSource={data}
      pagination={{ position: ['bottomLeft'], pageSize: 8 }}
      loading={dataLoading || actionLoading}
    />
  );
};
