import React from 'react';
import CMS from '../../cms/cms.json';
import { Flex, Card } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';

export function VendorAdminHome() {
  const navigate = useNavigate();

  return (
    <div className="main-body" style={{ marginTop: '150px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#F7F9FB',
          width: '710px',
          height: '392px',
          borderRadius: '24px',
        }}
      >
        <div style={{ width: '571px', marginTop: '50px', height: '30px' }}>
          <h2>{CMS.pages.find((o) => o.name === 'vendorAdmin')?.title}</h2>
        </div>
        <Flex direction={'row'} marginTop={'80px'}>
          <Card
            className="menu-card"
            onClick={() => navigate('/create-vendor')}
          >
            <AddShoppingCartOutlinedIcon
              fontSize="large"
              style={{ marginLeft: '10px' }}
            />
            <Flex
              direction={'column'}
              justifyContent={'space-around'}
              alignItems={'flex-start'}
            >
              <b>{CMS.pages.find((o) => o.name === 'createVendor')?.title}</b>
              <p style={{ fontSize: '14px' }}>
                {CMS.text.find((o) => o.name === 'createVendorCard')?.title}
              </p>
            </Flex>
          </Card>

          <Card
            className="menu-card"
            onClick={() => navigate('/manage-vendor')}
          >
            <ShoppingCartOutlinedIcon
              fontSize="large"
              style={{ marginLeft: '10px' }}
            />
            <Flex
              direction={'column'}
              justifyContent={'space-around'}
              alignItems={'flex-start'}
            >
              <b>{CMS.pages.find((o) => o.name === 'manageVendors')?.title}</b>
              <p style={{ fontSize: '14px' }}>
                {CMS.text.find((o) => o.name === 'manageVendorCard')?.title}
              </p>
            </Flex>
          </Card>
        </Flex>
      </div>
    </div>
  );
}
