/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AmplifyAlertBanner } from '../../../components/AmplifyAlertBanner';
import { Table, Space, Input, Modal } from 'antd';
import { Button } from '@aws-amplify/ui-react';
import { EditOutlined } from '@mui/icons-material';
import CMS from '../../../cms/cms.json';
import { Auth } from 'aws-amplify';
import usersService from '../../../axios/usersService';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import BlockIcon from '@mui/icons-material/Block';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { filterArrayOfObjectsByString } from '../../../utils/filterArrayOfObjectsByString';
import sigmaService from '../../../axios/sigmaService';

export const ManageUser = () => {
  const [newData, setNewData] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [internalUsers, setInternalInternalUsers] = useState([]);
  const [externalUsers, setInternalExternalUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showInternalUsers, setShowInternalUsers] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({ variation: '', msg: '' });
  const [currentRow, setCurrentRow] = useState({});
  const [managing, setManaging] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const jwt = useRef();

  const columns = [
    {
      title: 'First Name',
      align: 'center',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      align: 'center',
      dataIndex: 'lastName',
    },
    {
      title: 'Phone',
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      align: 'center',
      dataIndex: 'mail',
    },
    {
      title: 'Vendor',
      align: 'center',
      dataIndex: 'vendorName',
    },
    {
      title: 'Created',
      align: 'center',
      dataIndex: 'createdAt',
    },
    {
      title: 'Edit',
      align: 'center',
      key: 'action',
      render: (_, row) => (
        <Space>
          <Button
            key="Edit"
            title="Edit"
            variation="link"
            size="small"
            onClick={() => {
              navigate('/edit-user', { state: { email: row.mail } });
            }}
          >
            <EditOutlined />
          </Button>
          {row.active ? (
            <Button
              key="Deactivate User"
              title="Deactivate User"
              variation="link"
              size="small"
              onClick={() => {
                setManaging('status');
                setCurrentRow(row);
                setModalMsg('Do you want to deactivate this user?');
                handleModal(true);
              }}
            >
              <BlockIcon />
            </Button>
          ) : (
            <Button
              key="Activate User"
              title="Activate User"
              variation="link"
              size="small"
              onClick={() => {
                setManaging('status');
                setCurrentRow(row);
                setModalMsg('Do you want to activate this user?');
                handleModal(true);
              }}
            >
              <ManageAccountsIcon />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const columnsInternal = [
    {
      title: 'First Name',
      align: 'center',
      dataIndex: 'username',
    },
    {
      title: 'Last Name',
      align: 'center',
      dataIndex: 'lastName',
    },
    {
      title: 'Phone',
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      align: 'center',
      dataIndex: 'mail',
    },
    {
      title: 'Vendor',
      align: 'center',
      dataIndex: 'vendorName',
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (_, row) => (
        <Space>
          {row.vendorName === 'INTERNAL' ? (
            <Button
              key="Promote to Admin"
              title="Promote to Admin"
              variation="link"
              size="small"
              onClick={() => {
                setManaging('admin');
                setCurrentRow(row);
                setModalMsg('Do you want to promote this user as Admin?');
                handleModal(true);
              }}
            >
              <VpnKeyIcon />
            </Button>
          ) : (
            <Button
              key="Demote to normal Internal User"
              title="Demote to normal Internal User"
              variation="link"
              size="small"
              onClick={() => {
                setManaging('admin');
                setCurrentRow(row);
                setModalMsg(
                  'Do you want to demote this user as Internal user?',
                );
                handleModal(true);
              }}
            >
              <VpnKeyOffIcon />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (newData) {
      Auth.currentSession()
        .then(async (session) => {
          jwt.current = session.getIdToken().getJwtToken();
          let usersListRes = await usersService.getUsers(jwt.current);
          let internalUserListRes = await usersService.fetchInternalUsers(
            jwt.current,
          );
          setLoading(false);

          usersListRes = usersListRes?.data?.map((item) => ({
            ...item,
            key: item.mail,
          }));
          internalUserListRes = internalUserListRes?.data?.map((item) => ({
            ...item,
            key: item.mail,
          }));
          setInternalInternalUsers(internalUserListRes);
          setInternalExternalUsers(usersListRes);
          setDataSource(showInternalUsers ? internalUserListRes : usersListRes);
          setFilteredData(
            showInternalUsers ? internalUserListRes : usersListRes,
          );
        })
        .catch((err) => {
          console.log('Error encountered when fetching users: ', err);
          setConfig({ variation: 'error', msg: 'Error fetching users' });
        });
      setNewData(false);
    } else {
      setDataSource(showInternalUsers ? internalUsers : externalUsers);
      setFilteredData(showInternalUsers ? internalUsers : externalUsers);
    }
  }, [newData, dataSource, showInternalUsers]);

  useEffect(() => {
    if (location.state) {
      const { variation, msg } = location.state;
      setConfig({ variation: variation, msg: msg });
    }
  }, []);

  const promoteUser = async (row) => {
    handleModal(false);
    setLoading(true);
    const resPromote = await usersService.manageInternalAdmin(
      row.mail,
      'promote',
    );
    if (resPromote) {
      setNewData(true);
    } else {
      setConfig({
        variation: 'error',
        msg: 'Something went wrong, please contact admin',
      });
    }
  };

  const demoteUser = async (row) => {
    handleModal(false);
    setLoading(true);
    const resPromote = await usersService.manageInternalAdmin(
      row.mail,
      'demote',
    );
    if (resPromote) {
      setNewData(true);
    } else {
      setConfig({
        variation: 'error',
        msg: 'Something went wrong, please contact admin',
      });
    }
  };

  const onSearchUsers = (event) => {
    let value = event?.currentTarget?.value ?? event;
    let valueInput = value.toLowerCase();
    let filteredData = [];
    filteredData = filterArrayOfObjectsByString(valueInput, dataSource);

    setFilteredData(filteredData);

    return filteredData;
  };

  const handleModal = (value) => {
    setConfirmationModal(value);
  };

  const activateUser = async (email) => {
    handleModal(false);
    setLoading(true);
    await usersService
      .manageUserStatus(email, 'activate')
      .then((res) => {
        if (res?.data?.data?.manageUserStatus) {
          setNewData(true);
        }
      })
      .catch((err) => {
        console.error('ERROR editing user: ', err);
        setConfig({
          variation: 'error',
          msg: 'Error activating user, please contact admin',
        });
      });
    handleModal(false);
  };

  const deactivateUser = async (email) => {
    handleModal(false);
    setLoading(true);
    await usersService
      .manageUserStatus(email, 'deactivate')
      .then((res) => {
        if (res?.data?.data?.manageUserStatus) {
          // deactivate the user in Sigma
          sigmaService.deactivateUser(email);
          setNewData(true);
        }
      })
      .catch((err) => {
        console.error('ERROR editing user: ', err);
        setConfig({
          variation: 'error',
          msg: 'Error deactivating user, please contact admin',
        });
      });
  };

  const manageActions = () => {
    switch (managing) {
      case 'status': {
        const email = currentRow.mail;
        currentRow.active ? deactivateUser(email) : activateUser(email);
        break;
      }
      case 'admin':
        currentRow.vendorName === 'INTERNAL'
          ? promoteUser(currentRow)
          : demoteUser(currentRow);
        break;

      default:
        break;
    }
  };

  return (
    <div id="user-list-body">
      <div className="alert-banner">
        <AmplifyAlertBanner isDismissible={true} config={config} />
      </div>
      <div className="manage-control">
        <div>
          <h2 className="manage-users-title">
            {CMS.pages.find((o) => o.name === 'manageUsers')?.title}
          </h2>
          <ToggleButtonGroup
            className="toggle-button-mode"
            value={showInternalUsers}
            exclusive
            sx={{ marginRight: '10px' }}
            onChange={() => {
              setShowInternalUsers(!showInternalUsers);
            }}
          >
            <ToggleButton className="dq-toggle-button" value={false}>
              External
            </ToggleButton>
            <ToggleButton className="dq-toggle-button" value={true}>
              Internal
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {showInternalUsers ? null : (
          <Button
            size="small"
            onClick={() => {
              navigate('/create-user');
            }}
          >
            Add New User
          </Button>
        )}
      </div>

      <Input.Search
        className="search-component"
        placeholder="Search Users"
        allowClear
        onSearch={onSearchUsers}
        onChange={onSearchUsers}
      />
      <Table
        className="users-table"
        columns={showInternalUsers ? columnsInternal : columns}
        bordered
        size="small"
        dataSource={filteredData}
        pagination={{ position: ['bottomLeft'], pageSize: 8 }}
        loading={loading}
      />

      <Modal
        open={showConfirmationModal}
        onOk={() => manageActions()}
        onCancel={() => handleModal(false)}
      >
        <p>{modalMsg}</p>
      </Modal>
    </div>
  );
};
