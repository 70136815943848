import React, { useEffect, useState } from 'react';
import usersService from '../../axios/usersService';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Loading from '../../components/Loading';

export function TrainingResources(page) {
  const [res, setres] = useState();
  const [pdf, setPdf] = useState('');
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState([]);
  const [tab, setTab] = useState('');
  const [folders, setFolders] = useState();
  const [initialLoad, setInitialLoad] = useState(true);

  const handleNestClick = (folderName) => {
    if (open.includes(folderName)) {
      setOpen(open.filter((item) => item !== folderName));
    } else {
      setOpen([...open, folderName]);
    }
  };
  const handleDocumentChange = (index, folderName) => {
    setPdf(res[folderName].urls[index]);
    setTitle(res[folderName].fileNames[index].replace('.webm', ''));
    setTab(`${index}${folderName}`);
  };
  useEffect(() => {
    const getTrainingFiles = async () => {
      let trainingDocs = await usersService.getTraining(
        'Tutorial-Resources/',
        'inline',
      );
      setres(trainingDocs);
      setFolders(Object.keys(trainingDocs));
    };
    if (page.page === 4) {
      getTrainingFiles();
    }
  }, [page]);

  useEffect(() => {
    //checks if glossary exists, else it defaults to the first folder found
    if (res && initialLoad && Object.keys(res).includes('Glossary')) {
      setInitialLoad(false);
      handleDocumentChange(0, 'Glossary');
      setOpen([...open, 'Glossary']);
    } else if (res && initialLoad) {
      setInitialLoad(false);
      handleDocumentChange(0, Object.keys(res)[0]);
      setOpen([...open, Object.keys(res)[0]]);
    }
  }, [res]);

  return (
    <div className="videos-container">
      <div className="video-player-container">
        <div className="pdf-player">
          {folders ? (
            <div className="pdf-player-contents">
              <h3 className="video-title">{title}</h3>
              <embed
                src={pdf}
                width="100%"
                height="100%"
                type="application/pdf"
              />
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <div className="video-list">
        <List
          key={'Resources'}
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Tutorial Resources
            </ListSubheader>
          }
        >
          {folders ? (
            folders.map((folderName) => (
              <div key={folderName}>
                <ListItemButton
                  key={folderName}
                  onClick={() => {
                    handleNestClick(folderName);
                  }}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={folderName} />
                  {open.includes(folderName) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={open.includes(folderName)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {res[folderName].fileNames &&
                      res[folderName].fileNames.map((fileName, index) => (
                        <ListItemButton
                          className={
                            tab === `${index}${folderName}`
                              ? 'selected-cat-tab'
                              : ''
                          }
                          key={index}
                          sx={{ pl: 4 }}
                          onClick={() => {
                            handleDocumentChange(index, folderName);
                          }}
                        >
                          <ListItemIcon>
                            <AttachFileIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={fileName.replace('.pdf', '')}
                          />
                        </ListItemButton>
                      ))}
                  </List>
                </Collapse>
              </div>
            ))
          ) : (
            <Loading />
          )}
        </List>
      </div>
    </div>
  );
}
