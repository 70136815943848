// columns for vendor management
export const manageBrokerColumns = [
  {
    title: 'Broker',
    align: 'center',
    dataIndex: 'broker_group_name', // name of property in object
    sorter: (a, b) =>
      a?.broker_group_name?.length - b?.broker_group_name?.length,
    defaultSortOrder: 'descend',
  },
  {
    title: 'Number of Vendors',
    align: 'center',
    dataIndex: 'broker_vendor_count', // name of property in object
  },
  {
    title: 'Number of Distinct Areas',
    align: 'center',
    dataIndex: 'broker_area_count', // name of property in object
  },
];
