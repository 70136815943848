/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { FiscalWeekRangePicker } from '../components/IsoWeekDatePicker';
import {
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Button,
  Tooltip,
} from '@mui/material';
import {
  getCannedTimeframeArr,
  getDatesPreviousYear,
  getDatesMatchCurrent,
  getMatchState,
} from '../utils/dateUtils';
import { FiltersContext } from '../components/Navigation/Routes';
import CMS from '../cms/cms.json';

export default function DateOptions(props) {
  const context = useContext(FiltersContext);
  const {
    viewType,
    cannedReport,
    setCannedReport,
    currentTimeframe,
    setCurrentTimeframe,
    previousTimeframe,
    setPreviousTimeframe,
    setWeekSelection,
  } = context;

  const { switchDisabled, setSwitchDisabled, switchOn, setSwitchOn } = props;
  const [weekly, setWeekly] = useState(false);
  const [weeklyTimeframe, setWeeklyTimeframe] = useState([]);
  const [currentWeekCleared, setCurrentWeekCleared] = useState(false);
  const [previousWeekCleared, setPreviousWeekCleared] = useState(false);
  const [useCalendar, setUseCalendar] = useState(false);
  const [matchEnabled, setMatchEnabled] = useState(false);

  const handleCurrentWeekChange = (e) => {
    if (e.length !== 0) {
      setCurrentWeekCleared(false);
      if (weekly) {
        context.setCurrentTimeframe(e);
        const prev = getDatesPreviousYear(cannedReport, e, false);
        setWeeklyTimeframe(prev);
      } else {
        setCurrentTimeframe(e);
        setMatchEnabled(getMatchState(e, switchOn));
        setCurrentWeekCleared(false);
      }
    } else {
      setCurrentWeekCleared(true);
    }
    setUseCalendar(true);
    setSwitchDisabled(false);
    setCannedReport(false);
  };

  const handlePreviousWeekChange = (e) => {
    if (e.length !== 0) {
      setPreviousWeekCleared(false);
      if (!weekly) {
        setPreviousTimeframe(e);
      }
      setCannedReport(false);
    } else {
      setPreviousWeekCleared(true);
    }
    setUseCalendar(true);
    setSwitchDisabled(false);
    setCannedReport(false);
  };

  const handleCannedReportChange = (e) => {
    const selectedCannedReport = e.target.innerText.split(' ')[0];
    setCannedReport(selectedCannedReport); // set canned report selection

    setPreviousWeekCleared(false);
    setCurrentWeekCleared(false);

    if (
      selectedCannedReport === 'YTD' ||
      selectedCannedReport === '4' ||
      selectedCannedReport === '13' ||
      selectedCannedReport === '26'
    ) {
      setSwitchDisabled(false);
      setUseCalendar(false);
    } else {
      setSwitchOn(false);
      setSwitchDisabled(true);
      setUseCalendar(false);
    }

    const curr = getCannedTimeframeArr(selectedCannedReport);
    const prev = getDatesPreviousYear(selectedCannedReport, curr, switchOn);

    setCurrentTimeframe(curr);
    setPreviousTimeframe(prev);

    if (props.page === 'weekly') {
      const prev = getDatesPreviousYear(selectedCannedReport, curr, false);
      setWeeklyTimeframe(prev);
    }

    setWeekSelection(selectedCannedReport);
  };

  const handleYearPeriodSwitch = (e) => {
    setSwitchOn(e.target.checked);
    if (!useCalendar) {
      const curr = [...currentTimeframe];
      const prev = getDatesPreviousYear(cannedReport, curr, e.target.checked);
      setPreviousTimeframe(prev);
    } else if (useCalendar) {
      setMatchEnabled(getMatchState(currentTimeframe, e.target.checked));
    }
  };
  const handleMatchCurrentButton = () => {
    const curr = [...currentTimeframe];
    const prev = getDatesMatchCurrent(curr, switchOn);
    setPreviousTimeframe(prev);
  };

  useEffect(() => {
    if (props.page === 'weekly') {
      setWeekly(true);
      const curr = [...currentTimeframe];
      const prev = getDatesPreviousYear(cannedReport, curr, false);
      setWeeklyTimeframe(prev);
    }
    switch (props.page) {
      case 'home':
        if (!context.cannedReport) {
          handleCannedReportChange({ target: { innerText: '52 weeks' } });
        }
        break;
      case 'fsr':
        if (viewType === 'insights' && !cannedReport) {
          handleCannedReportChange({ target: { innerText: '52 weeks' } });
        }
        break;
      default:
        break;
    }
  }, [viewType]);

  //Handles the state of the YoY - PoP switch
  useEffect(() => {
    context?.cannedReport !== '52'
      ? setSwitchDisabled(false)
      : setSwitchDisabled(true);
  }, [context.cannedReport, props.page]);

  return (
    <div id="date-options-container" className="date-options">
      <div
        className={
          props.page === 'fsr' && viewType === 'insights'
            ? 'top-iframe-div'
            : 'accordion-div'
        }
      >
        {props.page === 'home' ||
        (props.page === 'fsr' && viewType === 'insights') ? null : (
          <div className="timeframes-div">
            <div className="curr-prev">
              <p className="acc-titles">CURRENT TIMEFRAME</p>
              <FiscalWeekRangePicker
                handleWeekRangeChange={handleCurrentWeekChange}
                timeframe={currentWeekCleared ? [] : currentTimeframe}
              />
            </div>
            {props.page === 'purchase' || props.page === 'sales' ? null : (
              <div className="prev-div">
                <div className="curr-prev">
                  <p className="acc-titles">PREVIOUS TIMEFRAME</p>
                  <FiscalWeekRangePicker
                    handleWeekRangeChange={handlePreviousWeekChange}
                    timeframe={
                      weekly
                        ? weeklyTimeframe
                        : previousWeekCleared
                          ? []
                          : previousTimeframe
                    }
                    disableCalendar={weekly}
                  />
                </div>
                {props.page === 'weekly' ? null : (
                  <Tooltip
                    title="Match date is not available"
                    disableHoverListener={!matchEnabled}
                    disableFocusListener={!matchEnabled}
                  >
                    <div className="match-button-div">
                      <Button
                        variant="outlined"
                        onClick={handleMatchCurrentButton}
                        disabled={matchEnabled}
                      >
                        {' '}
                        Match{' '}
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        )}
        <div className="canned-options-div">
          <div className="switch-div">
            <p className="acc-titles">
              {' '}
              {`TIMEFRAME SELECTION${props.page === 'weekly' || (props.page === 'fsr' && viewType === 'insights') ? ' - Year-Over-Year' : ''}`}{' '}
            </p>
            {props.page === 'weekly' ||
            props.page === 'purchase' ||
            props.page === 'sales' ||
            (props.page === 'fsr' && viewType === 'insights') ? null : (
              <div className="period-switch-div">
                <h4 className="toggle-title">Year-over-Year</h4>
                <Switch
                  onChange={handleYearPeriodSwitch}
                  checked={switchOn}
                  disabled={switchDisabled}
                  size="small"
                />
                <h4 className="toggle-title">Period-over-Period</h4>
              </div>
            )}
          </div>

          <div
            className={
              props.page === 'fsr' && viewType === 'insights'
                ? 'canned-dates-alone'
                : 'toggle-btn-div'
            }
          >
            <ToggleButtonGroup
              className="toggle-button-date"
              value={cannedReport}
              exclusive
              onChange={handleCannedReportChange}
            >
              <ToggleButton value="YTD">
                {CMS.text.find((o) => o.name === 'ytd')?.title}
              </ToggleButton>
              <ToggleButton value="4">
                {CMS.text.find((o) => o.name === '4Weeks')?.title}
              </ToggleButton>
              <ToggleButton value="13">
                {CMS.text.find((o) => o.name === '13Weeks')?.title}
              </ToggleButton>
              <ToggleButton value="26">
                {CMS.text.find((o) => o.name === '26Weeks')?.title}
              </ToggleButton>
              <ToggleButton value="52">
                {CMS.text.find((o) => o.name === '52Weeks')?.title}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
