import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Form, Input, Button, Select, Radio, Switch, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNewValues, getValidSelections } from '../../../../utils/helpers';
import { updateTables } from './onSubmitHandler';
import { initializeForm } from './initializeForm';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useChildrenVendors } from '../../../../hooks/useChildrenVendors';
import { useUnsubscribedVendors } from '../../../../hooks/useUnsubscribedVendors';
import { useVendorGroupCategories } from '../../../../hooks/useVendorGroupCategories';
import { useSelectedVendorGroupCategories } from '../../../../hooks/useSelectedVendorGroupCategories';
import { useSelectedBrokerAreas } from '../../../../hooks/useSelectedBrokerAreas';
import { useBrokerAreas } from '../../../../hooks/useBrokerAreas';
import { options } from '../subscriptionOptions';
import { TreeSelector } from '../../../../components/VendorAdmin/TreeSelectConfig';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Loading from '../../../../components/Loading';
import CMS from '../../../../cms/cms.json';

export function EditVendor() {
  // constants
  const WAIT_TIME = 750; // ms to wait for the debounced search

  // location and navigate hooks
  const location = useLocation();
  const navigate = useNavigate();

  // state vars
  const [isChecked, setIsChecked] = useState(
    (status = location.state.status) => status === 'active',
  );
  const [brokerIsChecked, setBrokerIsChecked] = useState(
    location.state.isBroker,
  );
  const [, setVendorGroupNumber] = useState(location.state.vendorGroupNumber);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [categoryState, setCategoryState] = useState(null);
  const [customerIsChecked, setCustomerIsChecked] = useState(
    location.state.isCustomer,
  );

  // initial form values
  const [initialValues, setInitialValues] = useState(null);
  const [didInitialize, setDidInitialize] = useState(false);

  // form related items
  const [form] = Form.useForm();
  const [componentDisabled, setComponentDisabled] = useState(false);

  // tree component related items
  const [treeData, setTreeData] = useState([]);
  const [value, setValue] = useState([]); // TreeSelect needs that name
  const [displayedBrokerAreas, setDisplayedBrokerAreas] = useState([]);

  const [vendorLevel, setVendorLevel] = useState(
    `${location.state.subscriptionId}`,
  );
  const [vendorSearch, setVendorSearch] = useState('');

  // custom hook calls
  const debouncedVendorSearch = useDebounce(vendorSearch, WAIT_TIME);
  const { childrenVendors, childrenVendorsLoaded } = useChildrenVendors(
    location.state.vendorGroupNumber,
  );
  const { unsubscribedVendors, unsubscribedVendorsLoaded } =
    useUnsubscribedVendors(brokerIsChecked, debouncedVendorSearch);
  const { vendorGroupCategories, vendorGroupCategoriesLoaded } =
    useVendorGroupCategories(selectedVendors);
  const { selectedVendorGroupCategories, selectedVendorGroupCategoriesLoaded } =
    useSelectedVendorGroupCategories(location.state.vendorGroupNumber);
  const { brokerAreas, brokerAreasLoaded } = useBrokerAreas(
    brokerIsChecked,
    selectedVendors,
  );
  const {
    selectedBrokerAreas,
    setSelectedBrokerAreas,
    selectedBrokerAreasLoaded,
  } = useSelectedBrokerAreas(brokerIsChecked, location.state.vendorGroupNumber);
  const selectedChildVendors = useRef([]);
  const selectedBrokerAreasRef = useRef([]);

  // vendor list for dropdown
  const vendorList = useMemo(() => {
    if (didInitialize && unsubscribedVendors) {
      const newChildVendors = [...selectedChildVendors.current];
      const newVendorList = [...unsubscribedVendors];
      const unsubVendorIds = unsubscribedVendors.map((vendor) => vendor.id);
      newChildVendors.forEach((vendor) => {
        const id = vendor?.id;
        if (!unsubVendorIds.includes(id)) {
          newVendorList.unshift(vendor);
        }
      });
      const newVendorListOptions = newVendorList.map((vendor) => ({
        label: vendor.id + ' - ' + vendor.label,
        value: vendor.id,
      }));
      return newVendorListOptions;
    }
  }, [JSON.stringify(unsubscribedVendors), didInitialize]);

  const brokerList = useMemo(() => {
    if (
      brokerAreas &&
      brokerAreas.length > 0 &&
      selectedChildVendors.current.length > 0
    ) {
      const newList = [...brokerAreas];
      const brokerAreaIds = brokerAreas.map((area) => area.id);
      displayedBrokerAreas.forEach((area) => {
        if (!brokerAreaIds.includes(area.id)) {
          newList.push(area);
        }
      });
      return newList;
    } else {
      return [];
    }
  }, [JSON.stringify(brokerAreas), displayedBrokerAreas]);

  // tree component handlers
  const onChange = (newValue) => {
    setValue(newValue);
    setCategoryState(() => {
      if (newValue && newValue.length > 0) {
        return newValue;
      }
    });
  };

  const handleCustomerChange = () => {
    setCustomerIsChecked((val) => !val);
  };

  const handleChangeVendor = (event) => {
    if (event) {
      setSelectedVendors(event);

      // currVendors represents the state of everything a user currently has selected
      const currVendors = selectedChildVendors.current;
      // new vendors only contains vendors from the newly fetched unsubscribed vendors list
      const newVendors = unsubscribedVendors.filter((vendor) =>
        event.includes(vendor.id),
      );
      const currVendorsIds = currVendors.map((vendor) => vendor.id);
      const selectedVendors = getValidSelections(
        event,
        currVendors,
        currVendorsIds,
        newVendors,
      );

      selectedChildVendors.current = selectedVendors;

      setVendorSearch('');
      selectedBrokerAreasRef.current = [];
      setDisplayedBrokerAreas([]);
      form.setFieldsValue({ Area: [], value: [] });
    }
  };

  // changes subscription level of a vendor
  const handleSubscriptionChange = (event) => {
    const newVendorLevel = event?.target?.value;
    setVendorLevel(newVendorLevel);
  };

  const BrokerAreasTemp = useRef([]);
  const onSubmit = async (values) => {
    // going to submit updates to 3 tables
    setComponentDisabled(true);

    let brokerData = [];
    selectedBrokerAreasRef.current.forEach((crr) => {
      brokerList.forEach((obj) => {
        if (obj.id === crr) {
          brokerData.push(obj);
        }
      });
      selectedBrokerAreas.forEach((idx) => {
        if (idx.label === crr) {
          brokerData.push(idx);
        }
      });
    });
    BrokerAreasTemp.current = brokerData;
    const submittedFormData = await updateTables(
      values,
      vendorGroupCategories,
      location.state.vendorGroupNumber,
      location.state.subscriptionName,
      location.state.status,
      selectedChildVendors.current,
      BrokerAreasTemp.current,
    );
    if (submittedFormData.status) {
      const state = { variation: 'success', msg: submittedFormData.msg };
      navigate('/manage-vendor', { state: state });
    } else {
      const state = { variation: 'error', msg: submittedFormData.msg };
      navigate('/manage-vendor', { state: state });
    }
    setComponentDisabled(false);
  };

  useEffect(() => {
    if (
      selectedVendors.length == 0 &&
      childrenVendors &&
      selectedChildVendors.current.length == 0
    ) {
      selectedBrokerAreasRef.current = [];
      setDisplayedBrokerAreas([]);
      form.setFieldsValue({ Area: [], value: [] });
    }
  }, [JSON.stringify(selectedVendors), JSON.stringify(selectedChildVendors)]);

  useEffect(() => {
    if (brokerAreas && brokerAreas.length > 0) {
      const newBroker = brokerAreas.filter((area) =>
        brokerAreas.includes(area.id),
      );
      selectedBrokerAreasRef.current = [...newBroker];
      if (selectedBrokerAreas && selectedChildVendors.current.length > 0) {
        let updatedSelectedBrokerAreas = [];
        selectedBrokerAreas.forEach((obj) => {
          updatedSelectedBrokerAreas.push(obj.label);
        });
        selectedBrokerAreasRef.current = [...updatedSelectedBrokerAreas];
        setSelectedBrokerAreas([]);
      }
    }
  }, [JSON.stringify(brokerAreas)]);

  useEffect(() => {
    if (vendorLevel === '3' && categoryState) {
      setValue(categoryState);
    } else if (vendorLevel === '3' && !categoryState) {
      setValue(selectedVendorGroupCategories);
    } else {
      setValue([]);
    }
  }, [selectedVendorGroupCategories, vendorLevel, categoryState]);

  useEffect(() => {
    setCategoryState((cats) => {
      let newCategoryState = [];
      if (
        cats &&
        vendorGroupCategories &&
        cats.length > 0 &&
        vendorGroupCategories.length > 0
      ) {
        newCategoryState = getNewValues(vendorGroupCategories, cats);
      }
      setValue(newCategoryState);
      return newCategoryState;
    });
  }, [vendorGroupCategories]);

  useEffect(() => {
    // setFieldValue and setFieldsValue are different methods **
    form.setFieldsValue({ value: value });
  }, [form, value]);

  useEffect(() => {
    if (
      brokerAreasLoaded &&
      selectedChildVendors.current.length ==
        selectedBrokerAreasRef.current.length
    ) {
      form.setFieldsValue({ Area: selectedBrokerAreasRef.current });
    }
  }, [JSON.stringify(brokerList)]);

  useEffect(() => {
    if (childrenVendors && childrenVendors.length > 0) {
      const childVendorIds = childrenVendors.map((vendor) => vendor.id);
      setSelectedVendors(childVendorIds);
    }
  }, [JSON.stringify(childrenVendors)]);

  useEffect(() => {
    const formState = location.state;
    const initializedFormValues = initializeForm(
      formState,
      vendorLevel,
      isChecked,
      childrenVendors,
      childrenVendorsLoaded,
      unsubscribedVendorsLoaded,
      selectedVendorGroupCategories,
      selectedVendorGroupCategoriesLoaded,
      vendorGroupCategoriesLoaded,
      brokerAreasLoaded,
      selectedBrokerAreas,
      selectedBrokerAreasLoaded,
    );

    if (!didInitialize) {
      if (
        initializedFormValues &&
        Object.prototype.hasOwnProperty.call(initializedFormValues, 'success') &&
        initializedFormValues.success
      ) {
        const { initialValues } = initializedFormValues;
        selectedChildVendors.current = [...childrenVendors];
        setInitialValues(initialValues);
        setVendorGroupNumber(initialValues.vendorGroupNumber);
        setBrokerIsChecked(initialValues.isBroker);
        setValue(initialValues.value);
        setDidInitialize(true);
        setCategoryState(() => {
          if (initialValues.value && initialValues.value.length > 0) {
            return initialValues.value;
          }
        });

        if (initialValues.isBroker) {
          let updatedSelectedBrokerAreas = [];
          selectedBrokerAreas.forEach((obj) => {
            updatedSelectedBrokerAreas.push(obj.label);
          });
          selectedBrokerAreasRef.current = [...updatedSelectedBrokerAreas];
        }
      }
    }
  }, [
    childrenVendorsLoaded,
    unsubscribedVendorsLoaded,
    vendorGroupCategoriesLoaded,
    selectedVendorGroupCategoriesLoaded,
    brokerAreasLoaded,
    selectedBrokerAreasLoaded,
  ]);

  return didInitialize ? (
    <div id="edit-vendor">
      <h2 className="page-title">
        {CMS.pages.find((o) => o.name === 'editVendor')?.title}
      </h2>
      <Form.Item label="Vendor Group Number:" name="vendorGroupNumber">
        <Input
          className="input-vendor-group-number"
          disabled={componentDisabled}
          readOnly={true}
          defaultValue={initialValues.vendorGroupNumber}
        />
      </Form.Item>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}
        labelCol={{ span: 40 }}
        wrapperCol={{ span: 30 }}
        layout="vertical"
      >
        <div className="attribute-box">
          <div className="attribute-box-fields">
            <Form.Item
              label="Vendor Name:"
              name="vendorGroupName"
              required
              rules={[
                { required: true },
                {
                  message: 'Enter Unique Vendor Group Name.',
                  validator: (_, value) => {
                    if (
                      location.state.currentVendorNames.includes(
                        String.prototype.toUpperCase.call(value),
                      ) &&
                      location.state.vendorGroupName !== value
                    ) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <div>
              <Form.Item
                label="Active?:"
                name="status"
                required
                rules={[{ required: true }]}
              >
                <Switch
                  disabled={componentDisabled}
                  className="period-switch-div"
                  onChange={() => setIsChecked((val) => !val)}
                  checked={isChecked}
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Subscription:"
              name="vendorLevel"
              required
              rules={[{ required: true }]}
            >
              <Radio.Group
                disabled={componentDisabled}
                options={options}
                onChange={(e) => handleSubscriptionChange(e)}
                value={vendorLevel}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            <Form.Item
              label="Primary Contact Name:"
              name="primaryContactName"
              required
              rules={[{ required: true }]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Primary Contact Email:"
              name="primaryContactEmail"
              required
              rules={[{ required: true }]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Primary Contact Phone:"
              name="primaryContactPhoneNumber"
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Secondary Contact Name:"
              name="secondaryContactName"
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Secondary Contact Email:"
              name="secondaryContactEmail"
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Secondary Contact Phone:"
              name="secondaryContactPhoneNumber"
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Category Manager:"
              name="categoryManager"
              required
              rules={[{ required: true }]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Category Director:"
              name="categoryDirector"
              required
              rules={[{ required: true }]}
            >
              <Input disabled={componentDisabled} />
            </Form.Item>

            <Form.Item
              label="Vendors:"
              name="selectedVendors"
              required
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                disabled={componentDisabled}
                searchValue={vendorSearch}
                className="select-vendor"
                mode="multiple"
                popupClassName="select-vendors-popup"
                onSearch={(value) => setVendorSearch(value)}
                onChange={handleChangeVendor}
                notFoundContent={null}
                loading={!unsubscribedVendorsLoaded}
                autoClearSearchValue={false}
                suffixIcon={
                  !unsubscribedVendorsLoaded ? (
                    <Spin className="custom-spin" size="small" />
                  ) : null
                }
                value={selectedChildVendors.current}
                options={vendorList}
                filterOption={(value, option) =>
                  option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
                }
              />
            </Form.Item>

            <Form.Item
              label="Merch Categories:"
              name="value"
              required
              rules={[{ required: vendorLevel === '3' }]}
            >
              <TreeSelector
                treeData={treeData}
                setTreeData={setTreeData}
                value={value}
                setValue={setValue}
                categoriesList={vendorGroupCategories}
                vendorLevel={vendorLevel}
                onChange={onChange}
                selectedChildVendors={selectedVendors}
                loaded={vendorGroupCategoriesLoaded}
                disabled={componentDisabled}
              />
            </Form.Item>

            {vendorLevel === '3' ? (
              <Form.Item label="Customer?:" name="isCustomer">
                <Switch
                  className="period-switch-div"
                  onChange={handleCustomerChange}
                  checked={customerIsChecked}
                  size="large"
                />
              </Form.Item>
            ) : null}
          </div>
        </div>
        <div className="edit-vendor-buttons">
          <Form.Item>
            <Button
              className="cancel-button"
              onClick={() => {
                navigate('/manage-vendor');
              }}
              disabled={componentDisabled}
            >
              <div className="button-items">
                {CMS.pages.find((o) => o.name === 'cancel')?.title}
                <CloseRoundedIcon className="cancel-icon" />
              </div>
            </Button>

            <Button
              type="primary"
              className="save-button"
              htmlType="submit"
              disabled={componentDisabled}
            >
              <div className="button-items">
                {CMS.pages.find((o) => o.name === 'update')?.title}
                <SaveOutlinedIcon className="save-icon" />
              </div>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  ) : (
    <Loading />
  );
}
