import { useEffect, useState } from 'react';
import vendorsService from '../axios/vendorsService';

export const useGetBrokers = () => {
  const [brokerList, setBrokerList] = useState();
  const [brokerListLoaded, setBrokerListLoaded] = useState(false);

  useEffect(() => {
    async function getVendorBrokers() {
      setBrokerListLoaded(false);
      const vendorBrokerRes = await vendorsService.getBrokerList();
      setBrokerList(vendorBrokerRes);
      setBrokerListLoaded(true);
    }

    getVendorBrokers();
  }, []);

  return { brokerList, brokerListLoaded };
};
