export const initializeForm = (
  formState,
  vendorLevel,
  isChecked,
  childrenVendors,
  childrenVendorsLoaded,
  unsubscribedVendorsLoaded,
  selectedVendorGroupCategories,
  selectedVendorGroupCategoriesLoaded,
  vendorGroupCategoriesLoaded,
  brokerAreasLoaded,
  selectedBrokerAreas,
  selectedBrokerAreasLoaded,
) => {
  // eslint-disable-next-line no-unused-vars
  const { subscriptionId, status, isBroker, ...stateObj } = formState;

  if (!unsubscribedVendorsLoaded || !childrenVendorsLoaded) return;
  if (
    (!selectedVendorGroupCategoriesLoaded || !vendorGroupCategoriesLoaded) &&
    subscriptionId === 3
  )
    return;
  if ((!selectedBrokerAreasLoaded || !brokerAreasLoaded) && isBroker) return;

  const vendorIds = childrenVendors.map((vendor) => vendor.id);
  const initializedValues = {
    ...stateObj,
    isBroker: isBroker,
    status: isChecked,
    vendorLevel: vendorLevel,
    selectedVendors: vendorIds,
  };

  if (subscriptionId === 3) {
    initializedValues.value = selectedVendorGroupCategories;
  }

  if (isBroker) {
    initializedValues.Area = selectedBrokerAreas.map((area) => area.label);
  }

  return {
    success: true,
    initialValues: initializedValues,
  };
};
