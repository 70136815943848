import { useState, useEffect } from 'react';
import vendorsService from '../axios/vendorsService';

export const useSubscribedVendorAdminAttributes = (open) => {
  const [subscribedVendorAdminAttributes, setSubscribedVendorAttributes] =
    useState(null);
  const [
    subscribedVendorAdminAttributesLoading,
    setSubscribedVendorAttributesLoading,
  ] = useState(true);

  useEffect(() => {
    setSubscribedVendorAttributesLoading(true);

    const fetchSubscribedVendors = async () => {
      setSubscribedVendorAttributesLoading(true);
      setSubscribedVendorAttributes(null);
      const selectedVendorsResponse =
        await vendorsService.getSubscribedVendorAdminAttributes();
      const vendorData = selectedVendorsResponse.filter(
        (vendor) => vendor.vendorRole,
      );

      setSubscribedVendorAttributes(vendorData);
      setSubscribedVendorAttributesLoading(false);
    };

    fetchSubscribedVendors();
  }, [open]);

  return {
    subscribedVendorAdminAttributes,
    subscribedVendorAdminAttributesLoading,
  };
};
