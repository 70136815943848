// APPLICATION CONFIG
import React from 'react';
import { Amplify } from 'aws-amplify';
import { cognitoExports } from './cognito/cognitoConfig';

// NAV ROUTES
import ApplicationRoutes from './components/Navigation/Routes';

import awsExports from './aws-exports';
import featureToggle from './featureToggle.json';

// GENERAL STYLING
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import '@aws-amplify/ui-react/styles.css';
import './App.css';

let amplifyConfig = {
  ...awsExports,
  oauth: {
    ...cognitoExports.oauth,
    redirectSignIn: window.location.origin + '/',
    redirectSignOut: window.location.origin + '/signout',
  },
  federationTarget: cognitoExports.federationTarget,
  authenticationFlowType: featureToggle[process.env.REACT_APP_ENVIRONMENT][
    'passwordless-auth'
  ]
    ? 'CUSTOM_AUTH'
    : 'USER_SRP_AUTH',
};

Amplify.configure(amplifyConfig);

function App() {
  return (
    <div className="App">
      <Box className="app-box">
        <CssBaseline />
        <Container className="app-container" maxWidth={false}>
          <ApplicationRoutes />
        </Container>
      </Box>
    </div>
  );
}

export default App;
