import React, { useEffect } from 'react';
import App from '../App';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { SignInHeader } from './login/SignInHeader';
import { Header as AuthHeader } from './login/Header';
import { useNavigate } from 'react-router';

function AWSAuthenticator() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <App />;
}

export default withAuthenticator(AWSAuthenticator, {
  components: {
    Header: AuthHeader,
    SignIn: {
      Header: SignInHeader,
    },
  },
});
