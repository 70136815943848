import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logOut } from './helpers';

export function useIdleTimer(channel) {
  const inactivityTimer =
    process.env.REACT_APP_ENVIRONMENT === 'DEV' ? 10000 : 30000;

  const location = useLocation();
  let timeout = null;

  const restartTimer = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      logOut(channel);
    }, inactivityTimer * 60);
  };

  const onActivity = (e) => {
    if (e.data?.source === 'react-devtools-content-script') {
      return; // ignore the messages from dev tools - https://github.com/facebook/react-devtools/issues/812
    }
    restartTimer();
  };

  useEffect(() => {
    restartTimer();
    window.addEventListener('mousemove', onActivity);
    window.addEventListener('keypress', onActivity);
    window.addEventListener('click', onActivity);
    window.addEventListener('wheel', onActivity);
    window.addEventListener('message', onActivity);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onActivity);
        window.removeEventListener('keypress', onActivity);
        window.removeEventListener('click', onActivity);
        window.removeEventListener('wheel', onActivity);
        window.removeEventListener('message', onActivity);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
}
