import { Auth } from 'aws-amplify';
import {
  createInternalUser,
  deleteUserFilters,
  insertUserFilters,
  manageUserStatus,
  manageInternalAdmin,
} from '../graphql/mutations';
import { axiosRequest } from '../utils/axiosRequest';
import {
  getUserFilters,
  getSigmaUrl,
  getPeppers,
  getProducePeppers,
  getIsUserAuthorized,
  getUsersList,
  getUserInfo,
  getInternalUsersList,
  getNavigationForUser,
  getSubscriptionId,
  getS3Documents,
  getUserShareCodes,
} from '../graphql/queries';
import {
  createUser,
  editUser,
  duplicateUserFilters,
} from '../graphql/mutations';

const usersService = {
  getHeaders: async () => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const jwt = idToken.getJwtToken();
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: jwt,
    };
  },

  issueRequest: async (body, method) => {
    const headers = await usersService.getHeaders();
    const options = { headers, body, method: method };

    return await axiosRequest(options);
  },

  getUserInfo: async () => {
    const fetchUserEmail = async () => {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return idToken.payload.email;
    };

    const method = 'post';
    const mail = await fetchUserEmail();

    const body = {
      query: getUserInfo,
      variables: {
        mail,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.getUserInfo;

    return data;
  },

  insertUserFilters: async (filterName, filters, viewType) => {
    const method = 'post';
    const body = {
      query: insertUserFilters,
      variables: {
        name: filterName,
        filters: JSON.stringify(filters),
        reportType: viewType,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.insertUserFilters;

    return JSON.parse(data);
  },

  createInternalUser: async (email, firstName, lastName) => {
    const method = 'post';
    const body = {
      query: createInternalUser,
      variables: {
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.createInternalUser;

    return data;
  },

  getIsUserAuthorized: async () => {
    const method = 'post';
    const body = {
      query: getIsUserAuthorized,
      variables: {},
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.isUserAuthorized;

    return data;
  },

  getUserFilters: async (viewType) => {
    const method = 'post';
    const body = {
      query: getUserFilters,
      variables: {
        reportType: viewType,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.getUserFilters;

    return JSON.parse(data);
  },

  getUserShareCodes: async (viewType) => {
    const method = 'post';
    const body = {
      query: getUserShareCodes,
      variables: {
        reportType: viewType,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.getUserShareCodes;

    return JSON.parse(data);
  },

  duplicateFiltersFromShareCode: async (shareCode, viewType) => {
    const method = 'post';
    const body = {
      query: duplicateUserFilters,
      variables: {
        shareCode,
        reportType: viewType,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.duplicateUserFilters;

    return JSON.parse(data);
  },

  deleteUserFilters: async (name, viewType) => {
    const method = 'post';
    const body = {
      query: deleteUserFilters,
      variables: {
        name: name,
        reportType: viewType,
      },
    };

    const res = await usersService.issueRequest(body, method);
    const data = res.data?.data?.deleteUserFilters;

    return JSON.parse(data);
  },

  getUsers: async () => {
    const method = 'post';
    const body = { query: getUsersList };

    const res = await usersService.issueRequest(body, method);

    return res.data?.data?.getUsersList;
  },

  userInfo: async (mail) => {
    const method = 'post';
    const body = {
      query: getUserInfo,
      variables: {
        mail: mail,
      },
    };

    const res = await usersService.issueRequest(body, method);
    return res.data?.data?.getUserInfo?.data[0];
  },

  editUserAtt: async (
    email,
    newEmail,
    vendorGroupNumber,
    firstName,
    lastName,
    phone,
    associatedBroker,
  ) => {
    const method = 'post';
    const body = {
      query: editUser,
      variables: {
        mail: email,
        newMail: newEmail,
        vendorGroupNumber: vendorGroupNumber,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        associatedBroker: associatedBroker,
      },
    };

    const res = await usersService.issueRequest(body, method);
    return res.data?.data?.editUser;
  },

  manageUserStatus: async (email, action) => {
    const method = 'post';
    const body = {
      query: manageUserStatus,
      variables: {
        mail: email,
        action: action,
      },
    };
    const result = await usersService.issueRequest(body, method);

    return result;
  },

  fetchInternalUsers: async () => {
    const method = 'post';
    const body = { query: getInternalUsersList };

    const res = await usersService.issueRequest(body, method);
    return res.data?.data?.getInternalUsersList;
  },
  getTraining: async (folder, type) => {
    const method = 'post';
    const body = {
      query: getS3Documents,
      variables: {
        folder: folder,
        type: type,
      },
    };
    const res = await usersService.issueRequest(body, method);
    return JSON.parse(res.data?.data?.getS3Documents);
  },
  getNav: async (userContext = {}) => {
    const method = 'post';

    const body = {
      query: getNavigationForUser,
      variables: {
        userContext: JSON.stringify(userContext),
      },
    };

    const res = await usersService.issueRequest(body, method);
    // have to sort client side due to app sync limitation
    return res.data?.data?.getNavigationForUser?.navItems.sort(function (a, b) {
      return a.order - b.order;
    });
  },

  createVendorUser: async (
    email,
    firstName,
    lastName,
    vendorGroupNumber,
    phone,
    isBroker,
    associatedBroker,
  ) => {
    const method = 'post';

    const body = {
      query: createUser,
      variables: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        vendorGroupNumber: vendorGroupNumber,
        phone: phone,
        isBroker: isBroker,
        associatedBroker: associatedBroker,
      },
    };

    const res = await usersService.issueRequest(body, method);

    return res.data?.data?.createUser;
  },

  manageInternalAdmin: async (email, action) => {
    const method = 'post';

    const body = {
      query: manageInternalAdmin,
      variables: {
        email: email,
        action: action,
      },
    };

    const res = await usersService.issueRequest(body, method);

    return res.data?.data?.manageInternalAdmin;
  },

  getSubId: async () => {
    const method = 'post';
    const body = { query: getSubscriptionId };

    const res = await usersService.issueRequest(body, method);

    return res.data?.data?.getSubscriptionId;
  },

  getEmbeddingUrl: async (submenu, filters, roleInformation, impersonate) => {
    const method = 'post';
    const body = {
      query: getSigmaUrl,
      variables: {
        submenu: submenu,
        filters: JSON.stringify(filters),
        sfRole: roleInformation?.sfRole,
        impersonate: impersonate,
      },
    };

    const res = await usersService.issueRequest(body, method);

    return res.data?.data?.getSigmaUrl?.embedding_url;
  },

  getPeppers: async (vendor, week, roleInformation) => {
    const formattedVendor = vendor
      ? `'${decodeURIComponent(vendor).replace(/,/g, "','")}'`
      : null;
    const method = 'post';
    const body = {
      query: getPeppers,
      variables: {
        vendor: formattedVendor,
        week: week,
        roleInformation: roleInformation,
      },
    };

    const res = await usersService.issueRequest(body, method);
    let peppers = res.data.data.getPeppers;
    if (res && !res.errors) {
      peppers = {
        pepperFR: peppers.presignedURLFR.replace('https://', ''),
        pepperOT: peppers.presignedURLOT.replace('https://', ''),
        pepperWS: peppers.presignedURLWS.replace('https://', ''),
      };
    }

    return peppers;
  },

  getProducePeppers: async (
    vendor,
    week,
    asysCode,
    asysCodeDesc,
    region,
    area,
    market,
    roleInformation,
  ) => {
    const formattedVendor = vendor
      ? decodeURIComponent(vendor.replace(/,/g, '|'))
      : null;
    const formattedAsysCodeDesc = asysCodeDesc
      ? decodeURIComponent(asysCodeDesc.replace(/,/g, '|')).replace(/'/g, "\\'")
      : null;
    const formattedMarket = market
      ? decodeURIComponent(market.replace(/,/g, '|')).replace(/'/g, "\\'")
      : null;
    const method = 'post';
    const body = {
      query: getProducePeppers,
      variables: {
        vendor: formattedVendor,
        week: week,
        asysCode: asysCode,
        asysCodeDesc: formattedAsysCodeDesc,
        region: region,
        area: area,
        market: formattedMarket,
        roleInformation: roleInformation,
      },
    };

    const res = await usersService.issueRequest(body, method);
    let peppers = res.data.data.getProducePeppers;
    if (res && !res.errors) {
      peppers = {
        pepperFR: peppers.presignedURLFR.replace('https://', ''),
        pepperWS: peppers.presignedURLWS.replace('https://', ''),
      };
    }

    return peppers;
  },
};

export default usersService;
