import React, { useEffect, useState } from 'react';
import usersService from '../../axios/usersService';
import Loading from '../../components/Loading';

export function TrainingSummary(page) {
  const [res, setres] = useState();
  const [folders, setFolders] = useState();

  const DownloadFile = (name) => {
    window.open(
      res[folders[0]].urls[
        res[folders[0]].fileNames.findIndex((fileName) =>
          fileName.includes(name),
        )
      ],
    );
  };
  useEffect(() => {
    const getTrainingFiles = async () => {
      let trainingDocs = await usersService.getTraining(
        'Summary/',
        'attachment',
      );
      setres(trainingDocs);
      setFolders(Object.keys(trainingDocs));
    };
    if (page.page === 1) {
      getTrainingFiles();
    }
  }, [page]);

  return (
    <div className="summary-container">
      {res ? (
        <div className="content-container">
          <div className="summary-header">
            <img
              alt="header"
              src="training-images/header-tutorial.png"
              style={{ width: '100%' }}
            ></img>
          </div>
          <div className="summary-text">
            <div className="text-box-1">
              <p className="tutorial-text-1">
                Shared Table not only provides you an efficient way to conduct
                data queries but also gives you the tools to make it easy to
                collaborate with suppliers and brokers on data-driven
                opportunities to drive growth. Suppliers purchase an annual
                Shared Table subscription to analyze their performance for all
                the products they sell through US Foods. The information
                provides insights into both sales performance as well as service
                levels and inventory metrics. With Shared Table you will not
                only be able to see exactly what a subscribing supplier sees but
                you will also have your own US Foods access to view additional
                metrics such as profitability. Internal users are finding Shared
                Table a quicker & easier way to access a lot of data typically
                extracted from Merlin & Discover.{' '}
              </p>
              <div className="collaboration-img">
                <img
                  alt="collaboration"
                  src="training-images/collaboration.png"
                ></img>
                <p>Data-Driven Supplier Collaboration</p>
              </div>
            </div>
            <div className="text-box-2">
              <div className="view-box">
                <img
                  alt="viewer"
                  src="training-images/viewer.png"
                  onClick={() => {
                    DownloadFile('Viewer');
                  }}
                ></img>
                <p className="tutorial-text-2">
                  Viewer contains the fundamental tools for data- driven
                  supplier collaboration. This is an Entry Level subscription
                  that includes the home page Business Review and Service &
                  Inventory Reports.
                </p>
                <p className="tutorial-text-3">
                  {' '}
                  Click on image above for more information.
                </p>
              </div>
              <div className="view-box">
                <img
                  alt="portfolio"
                  src="training-images/portfolio.png"
                  onClick={() => {
                    DownloadFile('Portfolio');
                  }}
                ></img>
                <p className="tutorial-text-2">
                  In additional to the Business Review and Service & Inventory
                  Reports, the Portfolio subscription includes a number of ad
                  hoc reporting features showing all the items a supplier sells
                  through US Foods and the category totals.{' '}
                </p>
                <p className="tutorial-text-3">
                  {' '}
                  Click on image above for more information.
                </p>
              </div>
              <div className="view-box">
                <img
                  alt="category"
                  src="training-images/category.png"
                  onClick={() => {
                    DownloadFile('Category');
                  }}
                ></img>
                <p className="tutorial-text-2">
                  In additional to the Business Review and Service & Inventory
                  Reports, the Portfolio subscription includes a number of ad
                  hoc reporting features showing all the items a supplier sells
                  through US Foods and the category totals.
                </p>
                <p className="tutorial-text-3">
                  {' '}
                  Click on image above for more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
