import { useCallback, useEffect, useState, useContext } from 'react';
import { FiltersContext } from '../components/Navigation/Routes';

export const useEventListener = (filterPageOpen) => {
  const context = useContext(FiltersContext);
  const { setFilterPageData } = context;
  const [data, setData] = useState(null);

  const update = useCallback((workbookVariables) => {
    const [controlId, controlValue] = Object.entries(workbookVariables).pop();
    setData({ type: 'update', data: { [controlId]: controlValue } });
  }, []);

  const captureWorkbookVariables = useCallback(
    (event) => {
      if (
        event.origin === 'https://app.sigmacomputing.com' &&
        event.data.type === 'workbook:variables:onchange'
      ) {
        try {
          update(event?.data?.workbook?.variables);
        } catch (e) {
          console.error('Unabled to update: ', e);
        }
      }

      if (
        event.origin === 'https://app.sigmacomputing.com' &&
        event.data.type === 'workbook:loaded'
      ) {
        setData({ type: 'load', time: event.timeStamp });
      }
    },
    [update],
  );

  const updateFilter = useCallback((filterVariables) => {
    const [controlId, controlValue] = Object.entries(filterVariables).pop();
    if (!controlId.includes('filter-control')) {
      //This excludes any filters that come from the filter-control-basic,filter-control-1, filter-control-vr-basic,filter-control-vr, filter-control-wi-basic,filter-control-wi
      setData({ type: 'updateFilter', data: { [controlId]: controlValue } });
    }
  }, []);

  const captureFiltersVariables = useCallback(
    (event) => {
      if (
        event.origin === 'https://app.sigmacomputing.com' &&
        event.data.type === 'workbook:variables:onchange'
      ) {
        try {
          updateFilter(event?.data?.workbook?.variables);
        } catch (e) {
          console.error('Unabled to update: ', e);
        }
      }

      if (
        event.origin === 'https://app.sigmacomputing.com' &&
        event.data.type === 'workbook:loaded'
      ) {
        setData({ type: 'loadFilter', time: event.timeStamp });
        setFilterPageData({ type: 'loadFilter', time: event.timeStamp });
      }
    },
    [updateFilter],
  );

  useEffect(() => {
    if (filterPageOpen) {
      window.addEventListener('message', captureFiltersVariables, '*');
    } else {
      window.addEventListener('message', captureWorkbookVariables, '*');
    }

    return () => {
      window.removeEventListener('message', captureWorkbookVariables, '*');
    };
  }, [captureWorkbookVariables, captureFiltersVariables, filterPageOpen]);

  return data;
};
