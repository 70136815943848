export const cognitoExports = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USERPOOLID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
  mandatorySignIn: true,
  oauth: {
    domain: process.env.REACT_APP_DOMAIN,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
    clientId: process.env.REACT_APP_CLIENTID,
    responseType: 'code',
  },
  federationTarget: process.env.REACT_APP_FEDERATION_TARGET,
};
