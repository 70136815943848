import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import enGB from 'antd/es/locale/en_GB';
var weekday = require('dayjs/plugin/weekday');
var updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.updateLocale('en-gb', {
  weekStart: 0,
});

const { RangePicker } = DatePicker;

export const FiscalWeekRangePicker = (props) => {
  const disabledDate = (current) => {
    const today = dayjs();
    const startRange = sundayOfWeek(today).subtract(108, 'w').subtract(1, 'd');
    const endRange = sundayOfWeek(today).subtract(1, 'd');

    return current < startRange || current > endRange;
  };

  const displayValue = () => {
    if (props.timeframe.length === 0) {
      return null;
    }

    const start = props.timeframe[0];
    const end = props.timeframe[props.timeframe.length - 1];

    return [dayjs(start), dayjs(end)];
  };

  const formatDate = (d) => {
    const sunday = sundayOfWeek(d);
    return sunday.format('YYYY-MM-DD (ww)');
  };

  const generateWeekRangeArr = (start, end) => {
    const weekRangeArr = [];
    weekRangeArr.push(start);

    let date = start;
    while (date !== end) {
      date = dayjs(date).add(1, 'w');
      date = formatDate(date);
      weekRangeArr.push(date);
    }

    return weekRangeArr;
  };

  const handleCalendarChange = (e) => {
    let data = [];
    if (e != null) {
      const startDate = formatDate(e[0]);
      const endDate = formatDate(e[1]);
      data = generateWeekRangeArr(startDate, endDate);
    }

    props.handleWeekRangeChange(data);
  };

  const sundayOfWeek = (d) => {
    return d.locale('en-gb').weekday(0);
  };

  return (
    <ConfigProvider locale={enGB}>
      <RangePicker
        id="range-picker"
        className="week-range-picker"
        picker="week"
        value={displayValue()}
        disabledDate={disabledDate}
        format={formatDate}
        onChange={handleCalendarChange}
        key={props.pseudoKey}
        disabled={props.disableCalendar}
      />
    </ConfigProvider>
  );
};
